export const huading = [
  /* 运营模块 */
  {
    name: "HuaDingOperate",
    path: "/huading/operate",
    component: () =>
      import(/* webpackChunkName:"huading" */ "../HuaDing/Operate/index"),
  }, //运营
  {
    name: "HuaDingOperateCustomerDetail",
    path: "/huading/operate/customerDetail",
    component: () =>
      import(
        /* webpackChunkName:"huading" */ "../HuaDing/Operate/customerDetail"
      ),
  }, //运营--客户排行详情
  {
    name: "HuaDingOperateProductDetail",
    path: "/huading/operate/productDetail",
    component: () =>
      import(
        /* webpackChunkName:"huading" */ "../HuaDing/Operate/productDetail"
      ),
  }, //运营--商品详情
  {
    name: "HuaDingCustComplaints",
    path: "/huading/custComplaints",
    component: () =>
      import(
        /* webpackChunkName:"huading" */ "../HuaDing/CustComplaints/index"
      ),
  }, //运营--客诉
  {
    name: "HuaDingCustomer",
    path: "/huading/customer",
    component: () =>
      import(/* webpackChunkName:"huading" */ "../HuaDing/Customer/index"),
  }, //运营--客户
  {
    name: "HuaDingSales",
    path: "/huading/sales",
    component: () =>
      import(/* webpackChunkName:"huading" */ "../HuaDing/Sales/index"),
  }, //运营--销售
  {
    name: "HuaDingSalesDetail",
    path: "/huading/sales/detail",
    component: () =>
      import(/* webpackChunkName:"huading" */ "../HuaDing/Sales/detail"),
  }, //运营--销售--客户详情
  {
    name: "HuaDingTransport",
    path: "/huading/transport",
    component: () =>
      import(/* webpackChunkName:"huading" */ "../HuaDing/Transport/index"),
  }, //运营--运输
  {
    name: "HuaDingTransportDetail",
    path: "/huading/transport/detail",
    component: () =>
      import(/* webpackChunkName:"huading" */ "../HuaDing/Transport/detail"),
  }, //运营--运输--客户详情

  /* 仓储模块 */
  {
    name: "HuaDingStockTurnover",
    path: "/huading/stockTurnover",
    component: () =>
      import(/* webpackChunkName:"huading" */ "../HuaDing/StockTurnover/index"),
  }, //仓储--存货周转
  {
    name: "HuaDingStockTurnoverCustomerDetail",
    path: "/huading/stockTurnover/customerDetail",
    component: () =>
      import(
        /* webpackChunkName:"huading" */ "../HuaDing/StockTurnover/customerDetail"
      ),
  }, //仓储--存货周转--客户排行详情
  {
    name: "HuaDingStockTurnoverProductDetail",
    path: "/huading/stockTurnover/productDetail",
    component: () =>
      import(
        /* webpackChunkName:"huading" */ "../HuaDing/StockTurnover/productDetail"
      ),
  }, //仓储--存货周转--周转详情
  {
    name: "HuaDingStoreHouse",
    path: "/huading/storeHouse",
    component: () =>
      import(/* webpackChunkName:"huading" */ "../HuaDing/StoreHouse/index"),
  }, //仓储--出入库
  {
    name: "HuaDingStoreHouseCustomerDetail",
    path: "/huading/storeHouse/customerDetail",
    component: () =>
      import(
        /* webpackChunkName:"huading" */ "../HuaDing/StoreHouse/customerDetail"
      ),
  }, //仓储--出入库--客户排行详情
  {
    name: "HuaDingStorageWork",
    path: "/huading/storage/work",
    component: () =>
      import(/* webpackChunkName:"huading" */ "../HuaDing/Storage/work"),
  }, //仓储--仓储作业
  {
    name: "HuaDingStorageWorkDetail",
    path: "/huading/storage/workDetail",
    component: () =>
      import(/* webpackChunkName:"huading" */ "../HuaDing/Storage/workDetail"),
  }, //仓储--仓储作业--客户排行详情
  {
    name: "HuaDingStorageSeat",
    path: "/huading/storage/seat",
    component: () =>
      import(/* webpackChunkName:"huading" */ "../HuaDing/Storage/seat"),
  }, //仓储--仓储库位
  {
    name: "HuaDingStorageCustomer",
    path: "/huading/storage/customer",
    component: () =>
      import(/* webpackChunkName:"huading" */ "../HuaDing/Storage/customer"),
  }, //仓储--仓储客户
  {
    name: "HuaDingStorageCustomerDetail",
    path: "/huading/storage/customerDetail",
    component: () =>
      import(
        /* webpackChunkName:"huading" */ "../HuaDing/Storage/customerDetail"
      ),
  }, //仓储--仓储客户--客户详情

  /* 财务模块 */
  {
    name: "HuaDingFinanceDashboard",
    path: "/huading/finance/dashboard",
    component: () =>
      import(/* webpackChunkName:"huading" */ "../HuaDing/Finance/dashboard"),
  }, //财务--月财务
  {
    name: "HuaDingFinanceMonth",
    path: "/huading/finance/month",
    component: () =>
      import(/* webpackChunkName:"huading" */ "../HuaDing/Finance/month"),
  }, //财务--月财务
  {
    name: "HuaDingFinanceWeek",
    path: "/huading/finance/week",
    component: () =>
      import(/* webpackChunkName:"huading" */ "../HuaDing/Finance/week"),
  }, //财务--周财务
  {
    name: "HuaDingFinanceCustomerDetail",
    path: "/huading/finance/detail",
    component: () =>
      import(/* webpackChunkName:"huading" */ "../HuaDing/Finance/detail"),
  }, //财务--客户详情
  {
    name: "HuaDingFinanceCustomer",
    path: "/huading/finance/customer",
    component: () =>
      import(/* webpackChunkName:"huading" */ "../HuaDing/Finance/customer"),
  }, //财务--新增客户
  {
    name: "HuaDingFinanceLossWarning",
    path: "/huading/finance/lossWarning",
    component: () =>
      import(/* webpackChunkName:"huading" */ "../HuaDing/Finance/lossWarning"),
  }, //财务--流失预警
];
