/** 逮虾记工厂业态 */
export const dxjFactory = [
  {
    path: "/dxjFactory/sales",
    name: "DxjFactorySales",
    component: () =>
      import(/*webpackChunkName:"dxjFactory" */ "../DxjFactory/Sales/index"),
  }, //销售
  {
    path: "/dxjFactory/sales/cityDetail",
    name: "DxjFactorySalesCityDetail",
    component: () =>
      import(
        /*webpackChunkName:"dxjFactory" */ "../DxjFactory/Sales/cityDetail"
      ),
  }, //销售--客户详情
  {
    path: "/dxjFactory/sales/storeDetail",
    name: "DxjFactorySalesStoreDetail",
    component: () =>
      import(
        /*webpackChunkName:"dxjFactory" */ "../DxjFactory/Sales/storeDetail"
      ),
  }, //销售--客户退货详情
  {
    path: "/dxjFactory/sales/sellerDetail",
    name: "DxjFactorySalesSellerDetail",
    component: () =>
      import(
        /*webpackChunkName:"dxjFactory" */ "../DxjFactory/Sales/sellerDetail"
      ),
  }, //销售--销售员详情
  {
    path: "/dxjFactory/sales/sellerRefundDetail",
    name: "DxjFactorySalesSellerRefundDetail",
    component: () =>
      import(
        /*webpackChunkName:"dxjFactory" */ "../DxjFactory/Sales/sellerRefundDetail"
      ),
  }, //销售--销售员退货详情
  {
    path: "/dxjFactory/product",
    name: "DxjFactoryProduct",
    component: () =>
      import(/*webpackChunkName:"dxjFactory" */ "../DxjFactory/Product/index"),
  }, //商品
  {
    path: "/dxjFactory/product/classificationDetail",
    name: "DxjFactoryProductClassificationDetail",
    component: () =>
      import(
        /*webpackChunkName:"dxjFactory" */ "../DxjFactory/Product/classificationDetail"
      ),
  }, //商品
  {
    path: "/dxjFactory/product/salesDetail",
    name: "DxjFactoryProductSalesDetail",
    component: () =>
      import(
        /*webpackChunkName:"dxjFactory" */ "../DxjFactory/Product/salesDetail"
      ),
  }, //商品--销售详情
  {
    path: "/dxjFactory/product/refundDetail",
    name: "DxjFactoryProductRefundDetail",
    component: () =>
      import(
        /*webpackChunkName:"dxjFactory" */ "../DxjFactory/Product/refundDetail"
      ),
  }, //商品--退货详情
  {
    path: "/dxjFactory/saling",
    name: "DxjFactorySaling",
    component: () =>
      import(/*webpackChunkName:"dxjFactory" */ "../DxjFactory/Saling/index"),
  }, //实时销售
  {
    path: "/dxjFactory/saling/saleDetail",
    name: "DxjFactorySalingSaleDetail",
    component: () =>
      import(
        /*webpackChunkName:"dxjFactory" */ "../DxjFactory/Saling/saleDetail"
      ),
  }, //实时销售--销售分类详情
  {
    path: "/dxjFactory/saling/storeDetail",
    name: "DxjFactorySalingStoreDetail",
    component: () =>
      import(
        /*webpackChunkName:"dxjFactory" */ "../DxjFactory/Saling/storeDetail"
      ),
  }, //实时销售--门店详情
  {
    path: "/dxjFactory/saling/salesDetail",
    name: "DxjFactorySalingSalesDetail",
    component: () =>
      import(
        /*webpackChunkName:"dxjFactory" */ "../DxjFactory/Saling/salesDetail"
      ),
  }, //实时销售--商品详情
  {
    path: "/dxjFactory/saling/sellerDetail",
    name: "DxjFactorySalingSellerDetail",
    component: () =>
      import(
        /*webpackChunkName:"dxjFactory" */ "../DxjFactory/Saling/sellerDetail"
      ),
  }, //实时销售--销售员详情
  {
    name: "DxjFactoryInventory",
    path: "/dxjFactory/inventory",
    component: () =>
      import(
        /*webpackChunkName:"dxjFactory" */ "../DxjFactory/Inventory/index"
      ),
  }, //存货模块
  {
    name: "DxjFactoryInventoryClassificationDetail",
    path: "/dxjFactory/inventory/classificationDetail",
    component: () =>
      import(
        /*webpackChunkName:"dxjFactory" */ "../DxjFactory/Inventory/classificationDetail"
      ),
  }, //存货模块--商品分类详情
  {
    name: "DxjFactoryInventoryDetail",
    path: "/dxjFactory/inventory/detail",
    component: () =>
      import(
        /*webpackChunkName:"dxjFactory" */ "../DxjFactory/Inventory/detail"
      ),
  }, //存货--分类详情
  {
    name: "DxjFactoryAbcDetail",
    path: "/dxjFactory/inventory/abcDetail",
    component: () =>
      import(
        /*webpackChunkName:"dxjFactory" */ "../DxjFactory/Inventory/abcDetail"
      ),
  }, //存货--ABC详情
  {
    name: "DxjFactoryRefundDetail",
    path: "/dxjFactory/inventory/refundDetail",
    component: () =>
      import(
        /*webpackChunkName:"dxjFactory" */ "../DxjFactory/Inventory/refundDetail"
      ),
  }, //存货--加工品存货数详情
  {
    name: "DxjFactoryCategoryDetail",
    path: "/dxjFactory/inventory/categoryDetail",
    component: () =>
      import(
        /* webpackChunkName: "dxjFactory" */ "../DxjFactory/Inventory/categoryDetail"
      ),
  }, //存货存货Top10详情
  {
    name: "DxjFactoryTurnoverDetail",
    path: "/dxjFactory/inventory/turnoverDetail",
    component: () =>
      import(
        /* webpackChunkName: "dxjFactory" */ "../DxjFactory/Inventory/turnoverDetail"
      ),
  }, //存货商品周转详情
  {
    path: "/dxjFactory/shopping",
    name: "DxjFactoryShopping",
    component: () =>
      import(
        /* webpackChunkName: "dxjFactory" */ "../DxjFactory/Shopping/index"
      ),
  }, //实时采购
  {
    path: "/dxjFactory/shopping/detail",
    name: "DxjFactoryShoppingDetail",
    component: () =>
      import(
        /* webpackChunkName:"dxjFactory" */ "../DxjFactory/Shopping/detail"
      ),
  }, //采购分类详情
  {
    path: "/dxjFactory/shopping/storeDetail",
    name: "DxjFactoryShoppingStoreDetail",
    component: () =>
      import(
        /* webpackChunkName:"dxjFactory" */ "../DxjFactory/Shopping/storeDetail"
      ),
  }, //供货商订货详情
  {
    path: "/dxjFactory/shopping/goodsDetail",
    name: "DxjFactoryShoppingGoodsDetail",
    component: () =>
      import(
        /* webpackChunkName:"dxjFactory" */ "../DxjFactory/Shopping/goodsDetail"
      ),
  }, //商品采购详情

  {
    path: "/dxjFactory/purchase",
    name: "DxjFactoryPurchase",
    component: () =>
      import(
        /* webpackChunkName:"dxjFactory" */ "../DxjFactory/Purchase/index"
      ),
  }, //采购
  {
    path: "/dxjFactory/purchase/classificationDetail",
    name: "DxjFactoryPurchaseClassificationDetail",
    component: () =>
      import(
        /* webpackChunkName:"dxjFactory" */ "../DxjFactory/Purchase/classificationDetail"
      ),
  }, //采购分类详情
  {
    path: "/dxjFactory/purchase/detail",
    name: "DxjFactoryPurchaseDetail",
    component: () =>
      import(
        /* webpackChunkName:"dxjFactory" */ "../DxjFactory/Purchase/detail"
      ),
  }, //采购商品详情--商品详情
  {
    path: "/dxjFactory/purchase/goodsDetail",
    name: "DxjFactoryPurchaseGoodsDetail",
    component: () =>
      import(
        /* webpackChunkName:"dxjFactory" */ "../DxjFactory/Purchase/goodsDetail"
      ),
  }, //采购商品详情--商品详情
  {
    path: "/dxjFactory/purchase/storeDetail",
    name: "DxjFactoryPurchaseStoreDetail",
    component: () =>
      import(
        /* webpackChunkName:"dxjFactory" */ "../DxjFactory/Purchase/storeDetail"
      ),
  }, //供货商订货详情

  {
    path: "/dxjFactory/production",
    name: "DxjFactoryProduction",
    component: () =>
      import(
        /* webpackChunkName:"dxjFactory" */ "../DxjFactory/Production/index"
      ),
  }, //生产
  {
    path: "/dxjFactory/production/goodsStockDetail",
    name: "DxjFactoryProductionStockDetail",
    component: () =>
      import(
        /* webpackChunkName:"dxjFactory" */ "../DxjFactory/Production/goodsStockDetail"
      ),
  }, //生产-存货量
];
