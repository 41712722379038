/**小小鱼业态 */
export const smallfish = [
  {
    path: "/smallfish/purchase",
    name: "SmallFishPurchase",
    component: () =>
      import(/*webpackChunkName:"smallfish" */ "../SmallFish/Purchase/index"),
  }, //采购
  {
    path: "/smallfish/purchase/classificationDetail",
    name: "SmallFishPurchaseClassificationDetail",
    component: () =>
      import(
        /*webpackChunkName:"smallfish" */ "../SmallFish/Purchase/classificationDetail"
      ),
  }, //采购
  {
    path: "/smallfish/purchase/cityDetail",
    name: "SmallFishCityDetail",
    component: () =>
      import(
        /*webpackChunkName:"smallfish" */ "../SmallFish/Purchase/cityDetail"
      ),
  }, //采购--城市详情
  {
    path: "/smallfish/purchase/storeDetail",
    name: "SmallFishStoreDetail",
    component: () =>
      import(
        /*webpackChunkName:"smallfish" */ "../SmallFish/Purchase/storeDetail"
      ),
  }, //采购--门店详情
  {
    path: "/smallfish/purchase/goodsDetail",
    name: "SmallFishGoodsDetail",
    component: () =>
      import(
        /*webpackChunkName:"smallfish" */ "../SmallFish/Purchase/goodsDetail"
      ),
  }, //采购--商品采购详情
  {
    path: "/smallfish/sales/index",
    name: "SmallFishSales",
    component: () =>
      import(/*webpackChunkName:"smallfish" */ "../SmallFish/Sales/index"),
  }, //销售
  {
    path: "/smallfish/sales/cityDetail",
    name: "SmallFishSalesCityDetail",
    component: () =>
      import(/*webpackChunkName:"smallfish" */ "../SmallFish/Sales/cityDetail"),
  }, //销售--城市详情
  {
    path: "/smallfish/sales/storeDetail",
    name: "SmallFishSalesStoreDetail",
    component: () =>
      import(
        /*webpackChunkName:"smallfish" */ "../SmallFish/Sales/storeDetail"
      ),
  }, //销售--门店详情
  {
    path: "/smallfish/cost/index",
    name: "SmallFishCost",
    component: () =>
      import(/*webpackChunkName:"smallfish" */ "../SmallFish/Cost/index"),
  }, //费用
  {
    path: "/smallfish/cost/cityDetail",
    name: "SmallFishCostCityDetail",
    component: () =>
      import(/*webpackChunkName:"smallfish" */ "../SmallFish/Cost/cityDetail"),
  }, //费用--城市详情
  {
    path: "/smallfish/cost/storeDetail",
    name: "SmallFishCostStoreDetail",
    component: () =>
      import(/*webpackChunkName:"smallfish" */ "../SmallFish/Cost/storeDetail"),
  }, //费用--门店详情
  {
    path: "/smallfish/cost/goodsDetail",
    name: "SmallFishCostGoodsDetail",
    component: () =>
      import(/*webpackChunkName:"smallfish" */ "../SmallFish/Cost/goodsDetail"),
  }, //费用--商品详情
  {
    path: "/smallfish/product/index",
    name: "SmallFishProduct",
    component: () =>
      import(/*webpackChunkName:"smallfish" */ "../SmallFish/Product/index"),
  }, //商品
  {
    path: "/smallfish/product/classificationDetail",
    name: "SmallFishProductClassificationDetail",
    component: () =>
      import(
        /*webpackChunkName:"smallfish" */ "../SmallFish/Product/classificationDetail"
      ),
  }, //商品--商品分类详情
  {
    path: "/smallfish/product/salesDetail",
    name: "SmallFishProductSalesDetail",
    component: () =>
      import(
        /*webpackChunkName:"smallfish" */ "../SmallFish/Product/salesDetail"
      ),
  }, //商品--销售详情
  {
    path: "/smallfish/product/refundDetail",
    name: "SmallFishProductRefundDetail",
    component: () =>
      import(
        /*webpackChunkName:"smallfish" */ "../SmallFish/Product/refundDetail"
      ),
  }, //商品--退货详情
  {
    path: "/smallfish/product/goodsDetail",
    name: "SmallFishProductGoodsDetail",
    component: () =>
      import(
        /*webpackChunkName:"smallfish" */ "../SmallFish/Product/goodsDetail"
      ),
  }, //商品--商品详情
  {
    path: "/smallfish/members/index",
    name: "SmallFishMembersIndex",
    component: () =>
      import(/*webpackChunkName:"smallfish" */ "../SmallFish/Members/index"),
  }, //会员
  {
    path: "/smallfish/members/cityDetail",
    name: "SmallFishMembersCityDetail",
    component: () =>
      import(
        /*webpackChunkName:"smallfish" */ "../SmallFish/Members/cityDetail"
      ),
  }, //会员--城市详情
  {
    path: "/smallfish/members/memberDetail",
    name: "SmallFishMembersMemberDetail",
    component: () =>
      import(
        /*webpackChunkName:"smallfish" */ "../SmallFish/Members/memberDetail"
      ),
  }, //会员--会员详情
  {
    path: "/smallfish/members/storeDetail",
    name: "SmallFishMembersStoreDetail",
    component: () =>
      import(
        /*webpackChunkName:"smallfish" */ "../SmallFish/Members/storeDetail"
      ),
  }, //会员--门店详情
  {
    path: "/smallfish/third/index",
    name: "SmallFishThird",
    component: () =>
      import(/*webpackChunkName:"smallfish" */ "../SmallFish/Third/index"),
  }, //三方销售
  {
    path: "/smallfish/third/cityDetail",
    name: "SmallFishThirdCityDetail",
    component: () =>
      import(/*webpackChunkName:"smallfish" */ "../SmallFish/Third/cityDetail"),
  }, //三方销售--城市详情
  {
    path: "/smallfish/third/storeDetail",
    name: "SmallFishThirdStoreDetail",
    component: () =>
      import(
        /*webpackChunkName:"smallfish" */ "../SmallFish/Third/storeDetail"
      ),
  }, //三方销售--门店详情
  {
    path: "/smallfish/saling/index",
    name: "SmallFishSaling",
    component: () =>
      import(/*webpackChunkName:"smallfish" */ "../SmallFish/Saling/index"),
  }, //实时销售
  {
    path: "/smallfish/saling/saleDetail",
    name: "SmallFishSalingSaleDetail",
    component: () =>
      import(
        /*webpackChunkName:"smallfish" */ "../SmallFish/Saling/saleDetail"
      ),
  }, //实时销售--商品分类销售详情
  {
    path: "/smallfish/saling/cityDetail",
    name: "SmallFishSalingCityDetail",
    component: () =>
      import(
        /*webpackChunkName:"smallfish" */ "../SmallFish/Saling/cityDetail"
      ),
  }, //实时销售--城市详情
  {
    path: "/smallfish/saling/storeDetail",
    name: "SmallFishSalingStoreDetail",
    component: () =>
      import(
        /*webpackChunkName:"smallfish" */ "../SmallFish/Saling/storeDetail"
      ),
  }, //实时销售--门店详情
  {
    path: "/smallfish/saling/salesDetail",
    name: "SmallFishSalingSalesDetail",
    component: () =>
      import(
        /*webpackChunkName:"smallfish" */ "../SmallFish/Saling/salesDetail"
      ),
  }, //实时销售--商品详情
  {
    path: "/smallfish/dailyReport",
    name: "SmallFishDailyReport",
    component: () =>
      import(
        /*webpackChunkName:"smallfish" */ "../SmallFish/DailyReport/index"
      ),
  }, //日报表
];
