/* 柒号胡同业态 */
export const hutong = [
  {
    path: "/hutong/home",
    name: "HutongHome",
    component: () =>
      import(/* webpackChunkName:"hutong" */ "../Hutong/Home/index"),
  }, //实时销售
  {
    path: "/hutong/home/classificationDetail",
    name: "HutongHomeClassificationDetail",
    component: () =>
      import(
        /* webpackChunkName:"hutong" */ "../Hutong/Home/classificationDetail"
      ),
  }, //实时销售--商品分类详情
  {
    path: "/hutong/home/customeDetail",
    name: "HutongCustomeDetail",
    component: () =>
      import(/* webpackChunkName:"hutong" */ "../Hutong/Home/customeDetail"),
  }, //客户销售详情
  {
    path: "/hutong/home/goodsDetail",
    name: "HutongGoodsDetail",
    component: () =>
      import(/* webpackChunkName:"hutong" */ "../Hutong/Home/goodsDetail"),
  }, //热销商品详情
  {
    path: "/hutong/purchase",
    name: "HutongPurchase",
    component: () =>
      import(/* webpackChunkName:"hutong" */ "../Hutong/Purchase/index"),
  }, //实时采购
  {
    path: "/hutong/purchase/detail",
    name: "HutongPurchaseDetail",
    component: () =>
      import(/* webpackChunkName:"hutong" */ "../Hutong/Purchase/detail"),
  }, //实时采购--商品采购分类详情
  {
    path: "/hutong/purchase/storeDetail",
    name: "HutongPurchaseStoreDetail",
    component: () =>
      import(/* webpackChunkName:"hutong" */ "../Hutong/Purchase/storeDetail"),
  }, //供货商订货详情
  {
    path: "/hutong/purchase/goodsDetail",
    name: "HutongPurchaseGoodsDetail",
    component: () =>
      import(/* webpackChunkName:"hutong" */ "../Hutong/Purchase/goodsDetail"),
  }, //商品采购详情

  {
    path: "/hutong/shopping",
    name: "HutongShopping",
    component: () =>
      import(/* webpackChunkName:"hutong" */ "../Hutong/Shopping/index"),
  }, //采购
  {
    path: "/hutong/shopping/classificationDetail",
    name: "HutongShoppingClassificationDetail",
    component: () =>
      import(
        /* webpackChunkName:"hutong" */ "../Hutong/Shopping/classificationDetail"
      ),
  }, //采购--商品分类构成详情
  {
    path: "/hutong/shopping/goodsDetail",
    name: "HutongShoppingGoodsDetail",
    component: () =>
      import(/* webpackChunkName:"hutong" */ "../Hutong/Shopping/goodsDetail"),
  }, //采购商品详情
  {
    path: "/hutong/shopping/detail",
    name: "HutongShoppingDetail",
    component: () =>
      import(/* webpackChunkName:"hutong" */ "../Hutong/Shopping/detail"),
  }, //采购商品详情--商品详情
  {
    path: "/hutong/shopping/storeDetail",
    name: "HutongShoppingStoreDetail",
    component: () =>
      import(/* webpackChunkName:"hutong" */ "../Hutong/Shopping/storeDetail"),
  }, //供货商订货详情

  {
    path: "/hutong/sales",
    name: "HutongSales",
    component: () =>
      import(/* webpackChunkName:"hutong" */ "../Hutong/Sales/index"),
  }, //销售
  {
    path: "/hutong/sales/customerDetail",
    name: "HutongSalescustomerDetail",
    component: () =>
      import(/* webpackChunkName:"hutong" */ "../Hutong/Sales/customerDetail"),
  }, //销售--客户详情
  {
    path: "/hutong/sales/sellerDetail",
    name: "HutongSalesSellerDetail",
    component: () =>
      import(/* webpackChunkName:"hutong" */ "../Hutong/Sales/sellerDetail"),
  }, //销售--客户退货详情

  {
    path: "/hutong/goods",
    name: "HutongGoods",
    component: () =>
      import(/* webpackChunkName:"hutong" */ "../Hutong/Goods/index"),
  }, //商品
  {
    path: "/hutong/goods/classificationDetail",
    name: "HutongGoodsClassificationDetail",
    component: () =>
      import(
        /* webpackChunkName:"hutong" */ "../Hutong/Goods/classificationDetail"
      ),
  }, //商品--商品分类销售
  {
    path: "/hutong/goods/salesDetail",
    name: "HutongGoodssalesDetail",
    component: () =>
      import(/* webpackChunkName:"hutong" */ "../Hutong/Goods/salesDetail"),
  }, //商品--热销详情
  {
    path: "/hutong/goods/customerDetail",
    name: "HutongGoodscustomerDetail",
    component: () =>
      import(/* webpackChunkName:"hutong" */ "../Hutong/Goods/customerDetail"),
  }, //商品--退货详情

  {
    name: "HutongInventory",
    path: "/hutong/inventory",
    component: () =>
      import(/*webpackChunkName:"hutong" */ "../Hutong/Inventory/index"),
  }, //存货模块
  {
    name: "HutongInventoryClassificationDetail",
    path: "/hutong/inventory/classificationDetail",
    component: () =>
      import(
        /*webpackChunkName:"hutong" */ "../Hutong/Inventory/classificationDetail"
      ),
  }, //存货模块
  {
    name: "HutongInventoryDetail",
    path: "/hutong/inventory/detail",
    component: () =>
      import(/*webpackChunkName:"hutong" */ "../Hutong/Inventory/detail"),
  }, //存货--分类详情
  {
    name: "HutongAbcDetail",
    path: "/hutong/inventory/abcDetail",
    component: () =>
      import(/*webpackChunkName:"hutong" */ "../Hutong/Inventory/abcDetail"),
  }, //存货--ABC详情
  {
    name: "HutongRefundDetail",
    path: "/hutong/inventory/refundDetail",
    component: () =>
      import(/*webpackChunkName:"hutong" */ "../Hutong/Inventory/refundDetail"),
  }, //存货--加工品存货数详情
  {
    name: "HutongTurnoverDetail",
    path: "/hutong/inventory/turnoverDetail",
    component: () =>
      import(
        /* webpackChunkName: "hutong" */ "../Hutong/Inventory/turnoverDetail"
      ),
  }, //存货周转详情
  {
    name: "HutongReportGood",
    path: "/hutong/reportGood",
    component: () =>
      import(/* webpackChunkName: "hutong" */ "../Hutong/ReportGood/index"),
  }, // 报货情况
  {
    name: "HutongReportGoodCustDetail",
    path: "/hutong/reportGood/custDetail",
    component: () =>
      import(
        /* webpackChunkName: "hutong" */ "../Hutong/ReportGood/custDetail"
      ),
  }, // 报货情况--客户详情
  {
    name: "HutongReportGoodGoodDetail",
    path: "/hutong/reportGood/goodDetail",
    component: () =>
      import(
        /* webpackChunkName: "hutong" */ "../Hutong/ReportGood/goodDetail"
      ),
  }, // 报货情况--商品详情
];
