import { createApp } from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
// import store from "./store";
import {
  Button,
  Popup,
  Calendar,
  Picker,
  TreeSelect,
  Search,
  Dialog,
  Loading,
  Toast,
  DropdownMenu,
  DropdownItem,
  List,
  DatetimePicker,
  Cell,
  Checkbox,
  CheckboxGroup,
  Field,
} from "vant";
import store from "./store";
import "./utils/rem";
import Empty from "./plugins/empty/index.js";

createApp(App)
  .use(router)
  .use(store)
  .use(Button)
  .use(Popup)
  .use(Calendar)
  .use(Picker)
  .use(TreeSelect)
  .use(Search)
  .use(Dialog)
  .use(Toast)
  .use(Loading)
  .use(Empty)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(List)
  .use(DatetimePicker)
  .use(Cell)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Field)
  .mount("#app");
