/** 菊花开业态 */
export const jhk = [
  {
    path: "/jhk/purchase",
    name: "JhkPurchase",
    component: () =>
      import(/*webpackChunkName:"jhk" */ "../Jhk/Purchase/index"),
  }, //采购
  {
    path: "/jhk/purchase/classificationDetail",
    name: "JhkPurchaseClassificationDetail",
    component: () =>
      import(
        /*webpackChunkName:"jhk" */ "../Jhk/Purchase/classificationDetail"
      ),
  }, //采购--商品采购分类
  {
    path: "/jhk/purchase/goodsDetail",
    name: "JhkGoodsDetail",
    component: () =>
      import(/*webpackChunkName:"jhk" */ "../Jhk/Purchase/goodsDetail"),
  }, //采购--商品采购详情
  {
    path: "/jhk/sales/index",
    name: "JhkSales",
    component: () => import(/*webpackChunkName:"jhk" */ "../Jhk/Sales/index"),
  }, //销售
  {
    path: "/jhk/sales/cityDetail",
    name: "JhkSalesCityDetail",
    component: () =>
      import(/*webpackChunkName:"jhk" */ "../Jhk/Sales/cityDetail"),
  }, //销售--城市详情
  {
    path: "/jhk/sales/storeDetail",
    name: "JhkSalesStoreDetail",
    component: () =>
      import(/*webpackChunkName:"jhk" */ "../Jhk/Sales/storeDetail"),
  }, //销售--门店详情
  {
    path: "/jhk/product/index",
    name: "JhkProduct",
    component: () => import(/*webpackChunkName:"jhk" */ "../Jhk/Product/index"),
  }, //商品
  {
    path: "/jhk/product/classificationDetail",
    name: "JhkProductClassificationDetail",
    component: () =>
      import(/*webpackChunkName:"jhk" */ "../Jhk/Product/classificationDetail"),
  }, //商品--商品分类详情
  {
    path: "/jhk/product/salesDetail",
    name: "JhkProductSalesDetail",
    component: () =>
      import(/*webpackChunkName:"jhk" */ "../Jhk/Product/salesDetail"),
  }, //商品--销售详情
  {
    path: "/jhk/product/refundDetail",
    name: "JhkProductRefundDetail",
    component: () =>
      import(/*webpackChunkName:"jhk" */ "../Jhk/Product/refundDetail"),
  }, //商品--退货详情
  {
    path: "/jhk/members/index",
    name: "JhkMembersIndex",
    component: () => import(/*webpackChunkName:"jhk" */ "../Jhk/Members/index"),
  }, //会员
  {
    path: "/jhk/members/cityDetail",
    name: "JhkMembersCityDetail",
    component: () =>
      import(/*webpackChunkName:"jhk" */ "../Jhk/Members/cityDetail"),
  }, //会员--城市详情
  {
    path: "/jhk/members/storeDetail",
    name: "JhkMembersStoreDetail",
    component: () =>
      import(/*webpackChunkName:"jhk" */ "../Jhk/Members/storeDetail"),
  }, //会员--门店详情
  {
    path: "/jhk/third/index",
    name: "JhkThird",
    component: () => import(/*webpackChunkName:"jhk" */ "../Jhk/Third/index"),
  }, //三方销售
  {
    path: "/jhk/third/cityDetail",
    name: "JhkThirdCityDetail",
    component: () =>
      import(/*webpackChunkName:"jhk" */ "../Jhk/Third/cityDetail"),
  }, //三方销售--城市详情
  {
    path: "/jhk/third/storeDetail",
    name: "JhkThirdStoreDetail",
    component: () =>
      import(/*webpackChunkName:"jhk" */ "../Jhk/Third/storeDetail"),
  }, //三方销售--门店详情
  {
    path: "/jhk/saling/index",
    name: "JhkSaling",
    component: () => import(/*webpackChunkName:"jhk" */ "../Jhk/Saling/index"),
  }, //实时销售
  {
    path: "/jhk/saling/saleDetail",
    name: "JhkSalingSaleDetail",
    component: () =>
      import(/*webpackChunkName:"jhk" */ "../Jhk/Saling/saleDetail"),
  }, //实时销售--商品销售分类
  {
    path: "/jhk/saling/storeDetail",
    name: "JhkSalingStoreDetail",
    component: () =>
      import(/*webpackChunkName:"jhk" */ "../Jhk/Saling/storeDetail"),
  }, //实时销售--门店详情
  {
    path: "/jhk/saling/salesDetail",
    name: "JhkSalingSalesDetail",
    component: () =>
      import(/*webpackChunkName:"jhk" */ "../Jhk/Saling/salesDetail"),
  }, //实时销售--商品详情
];
