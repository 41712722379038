/* 鹿邑澄明业态 */
export const luyi = [
  {
    path: "/luyi/home",
    name: "LuyiHome",
    component: () => import(/* webpackChunkName:"luyi" */ "../Luyi/Home/index"),
  }, //实时销售
  {
    path: "/luyi/home/classificationDetail",
    name: "LuyiHomeClassificationDetail",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Home/classificationDetail"),
  }, //实时销售--商品销售分类详情
  {
    path: "/luyi/home/storeDetail",
    name: "LuyiStoreDetail",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Home/storeDetail"),
  }, //客户销售详情
  {
    path: "/luyi/home/classDetail",
    name: "LuyiClassDetail",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Home/classDetail"),
  }, //商品销售分类详情
  {
    path: "/luyi/home/goodsDetail",
    name: "LuyiGoodsDetail",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Home/goodsDetail"),
  }, //热销商品详情

  {
    path: "/luyi/purchase",
    name: "LuyiPurchase",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Purchase/index"),
  }, //实时采购
  {
    path: "/luyi/purchase/saleDetail",
    name: "LuyiPurchaseSaleDetail",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Purchase/saleDetail"),
  }, //实时采购
  {
    path: "/luyi/purchase/storeDetail",
    name: "LuyiPurchaseStoreDetail",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Purchase/storeDetail"),
  }, //供货商订货详情
  {
    path: "/luyi/purchase/classDetail",
    name: "LuyiPurchaseClassDetail",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Purchase/classDetail"),
  }, //商品采购分类详情
  {
    path: "/luyi/purchase/goodsDetail",
    name: "LuyiPurchaseGoodsDetail",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Purchase/goodsDetail"),
  }, //商品采购详情

  {
    path: "/luyi/shopping",
    name: "LuyiShopping",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Shopping/index"),
  }, //采购
  {
    path: "/luyi/shopping/classificationDetail",
    name: "LuyiShoppingClassificationDetail",
    component: () =>
      import(
        /* webpackChunkName:"luyi" */ "../Luyi/Shopping/classificationDetail"
      ),
  }, //采购
  {
    path: "/luyi/shopping/classDetail",
    name: "LuyiShoppingClassDetail",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Shopping/classDetail"),
  }, //采购分类详情
  {
    path: "/luyi/shopping/goodsDetail",
    name: "LuyiShoppingGoodsDetail",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Shopping/goodsDetail"),
  }, //采购商品详情
  {
    path: "/luyi/shopping/detail",
    name: "LuyiShoppingDetail",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Shopping/detail"),
  }, //采购商品详情--商品详情
  {
    path: "/luyi/shopping/storeDetail",
    name: "LuyiShoppingStoreDetail",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Shopping/storeDetail"),
  }, //供货商订货详情

  {
    path: "/luyi/sales",
    name: "LuyiSales",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Sales/index"),
  }, //销售
  {
    path: "/luyi/sales/customerDetail",
    name: "LuyiSalescustomerDetail",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Sales/customerDetail"),
  }, //销售--客户详情

  {
    path: "/luyi/goods",
    name: "LuyiGoods",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Goods/index"),
  }, //商品
  {
    path: "/luyi/goods/classDetail",
    name: "LuyiGoodsclassDetail",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Goods/classDetail"),
  }, //商品--销售分类详情
  {
    path: "/luyi/goods/salesDetail",
    name: "LuyiGoodssalesDetail",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Goods/salesDetail"),
  }, //商品--热销详情
  {
    path: "/luyi/goods/customerDetail",
    name: "LuyiGoodscustomerDetail",
    component: () =>
      import(/* webpackChunkName:"luyi" */ "../Luyi/Goods/customerDetail"),
  }, //商品--退货详情

  {
    name: "LuyiInventory",
    path: "/luyi/inventory",
    component: () =>
      import(/*webpackChunkName:"luyi" */ "../Luyi/Inventory/index"),
  }, //存货模块
  {
    name: "LuyiInventoryClassificationDetail",
    path: "/luyi/inventory/classificationDetail",
    component: () =>
      import(
        /*webpackChunkName:"luyi" */ "../Luyi/Inventory/classificationDetail"
      ),
  }, //存货模块--商品分类详情
  {
    name: "LuyiInventoryDetail",
    path: "/luyi/inventory/detail",
    component: () =>
      import(/*webpackChunkName:"luyi" */ "../Luyi/Inventory/detail"),
  }, //存货--分类详情
  {
    name: "LuyiAbcDetail",
    path: "/luyi/inventory/abcDetail",
    component: () =>
      import(/*webpackChunkName:"luyi" */ "../Luyi/Inventory/abcDetail"),
  }, //存货--ABC详情
  {
    name: "LuyiRefundDetail",
    path: "/luyi/inventory/refundDetail",
    component: () =>
      import(/*webpackChunkName:"luyi" */ "../Luyi/Inventory/refundDetail"),
  }, //存货--加工品存货数详情
];
