import { createStore } from "vuex";

export default createStore({
  state: {
    warehouse: {
      name: "",
      number: "",
    }, // 仓库
  },
  mutations: {
    SET_WAREHOUSE(state, { name, number }) {
      state.warehouse = { name, number };
    },
  },
  actions: {},
  modules: {},
});
