/* 冻品批业态 */
export const wholesale = [
  {
    path: "/index",
    name: "WholesaleIndex",
    component: () =>
      import(/* webpackChunkName: "wholesale" */ "../Wholesale/index"),
  },
  {
    path: "/home",
    name: "WholesaleHome",
    component: () =>
      import(/* webpackChunkName: "wholesale" */ "../Wholesale/Home"),
  },
  {
    path: "/commodity",
    name: "WholesaleCommodity",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Commodity/Index"
      ),
  },
  {
    name: "WholesaleTheaterDetails",
    path: "/commodity/detail",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Commodity/Index/Details"
      ),
  },
  {
    name: "WholesaleSingleProduct",
    path: "/commodity/single",
    component: () =>
      import(
        /*webpackChunkName: "wholesale" */ "../Wholesale/Commodity/Single/Index"
      ),
  },
  {
    name: "WholesaleItemDetails",
    path: "/commodity/single/detail",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Commodity/Single/Details"
      ),
  },
  {
    name: "WholesaleGrossprofitdetails",
    path: "/commodity/single/profit",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Commodity/Single/Profit"
      ),
  },
  {
    name: "WholesaleCategory",
    path: "/commodity/category",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Commodity/Category/Index"
      ),
  },
  {
    name: "WholesaleCategoryDetails",
    path: "/commodity/category/detail",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Commodity/Category/Details"
      ),
  },
  {
    name: "WholesaleStore",
    path: "/commodity/store",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Commodity/Store/index"
      ),
  },
  {
    name: "WholesaleStoreDetails",
    path: "/commodity/store/detail",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Commodity/Store/detail"
      ),
  },

  // 营运板块
  {
    name: "WholesaleSummary",
    path: "/supply/summary",
    component: () =>
      import(/* webpackChunkName: "wholesale" */ "../Wholesale/Supply/summary"),
  }, //汇总看板
  {
    name: "WholesaleSale",
    path: "/supply/sale",
    component: () =>
      import(/* webpackChunkName: "wholesale" */ "../Wholesale/Supply/sale"),
  }, //销售
  {
    name: "WholesaleProfit",
    path: "/supply/profit",
    component: () =>
      import(/* webpackChunkName: "wholesale" */ "../Wholesale/Supply/profit"),
  }, //毛利
  {
    name: "WholesaleReturn",
    path: "/supply/return",
    component: () =>
      import(/* webpackChunkName: "wholesale" */ "../Wholesale/Supply/reurn"),
  }, //退货
  {
    name: "WholesaleReturnDetail",
    path: "/supply/return/detail",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Supply/reurn/detail"
      ),
  }, //退货详情

  {
    name: "WholesaleMarketing",
    path: "/supply/marketing",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Supply/marketing"
      ),
  }, //营销

  {
    name: "WholesaleMarketingDetail",
    path: "/supply/marketing/detail",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Supply/marketing/detail"
      ),
  }, //营销详情
  {
    name: "WholesaleExpand",
    path: "/supply/expand",
    component: () =>
      import(/* webpackChunkName: "wholesale" */ "../Wholesale/Supply/expand"),
  }, //拓展
  {
    name: "WholesaleExpandDetail",
    path: "/supply/expand/newDetail",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Supply/expand/newDetail"
      ),
  }, //拓展-新签约门店
  {
    name: "WholesaleCloseDetail",
    path: "/supply/expand/closeDetail",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Supply/expand/closeDetail"
      ),
  }, //拓展-闭店详情

  /**供应链 */
  {
    name: "WholesaleMarket",
    path: "/operation/market",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/market"
      ),
  }, //供应链销售
  {
    name: "WholesaleMarketClassificationDetail",
    path: "/operation/market/classificationDetail",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/market/classificationDetail"
      ),
  }, //销售--销售分类详情
  {
    name: "WholesaleSalesVolume",
    path: "/operation/market/detail",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/market/detail"
      ),
  }, //销售额
  {
    name: "WholesaleCommoditySales",
    path: "/operation/market/goods",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/market/goods"
      ),
  }, //商品销售
  {
    name: "WholesaleClient",
    path: "/operation/market/client",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/market/client"
      ),
  }, //客户销售
  {
    name: "WholesalePrice",
    path: "/operation/market/price",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/market/price"
      ),
  }, //客单价

  {
    name: "WholesaleGross",
    path: "/operation/profit",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/profit"
      ),
  }, //供应链毛利
  {
    name: "WholesaleGrossClassificationDetail",
    path: "/operation/profit/classificationDetail",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/profit/classificationDetail"
      ),
  }, //供应链毛利--品类毛利额详情

  {
    name: "WholesaleCommodityProfit",
    path: "/operation/profit/detail",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/profit/detail"
      ),
  }, //供应链商品毛利详情
  {
    name: "WholesaleCustomerProfit",
    path: "/operation/profit/cdetail",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/profit/cdetail"
      ),
  }, //供应链客户毛利详情

  {
    name: "WholesaleStock",
    path: "/operation/stock",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/stock"
      ),
  }, //供应链存货
  {
    name: "WholesaleStockInventoryDetail",
    path: "/operation/stock/inventoryDetail",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/stock/inventoryDetail"
      ),
  }, //供应链存货

  {
    name: "WholesaleABC",
    path: "/operation/stock/detail",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/stock/detail"
      ),
  }, //供应链存货ABC详情

  {
    name: "WholesaleWarning",
    path: "/operation/stock/warning",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/stock/warning"
      ),
  }, //供应链预警存货

  {
    name: "WholesaleTemporary",
    path: "/operation/stock/temporary",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/stock/temporary"
      ),
  }, //供应链临期预警
  {
    name: "WholesaleTurnoverDetail",
    path: "/operation/stock/turnoverDetail",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/stock/turnoverDetail"
      ),
  }, //供应链周转详情

  /**采购 */
  {
    name: "WholesalePurchase",
    path: "/operation/purchase",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/purchase"
      ),
  }, //供应链采购
  {
    name: "WholesalePurchaseClassificationDetail",
    path: "/operation/purchase/classificationDetail",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/purchase/classificationDetail"
      ),
  }, //供应链采购

  {
    name: "WholesalePurchaseDetail",
    path: "/operation/purchase/detail",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/purchase/detail"
      ),
  }, //供应链采购详情

  {
    name: "WholesalePurchaseRate",
    path: "/operation/purchase/rate",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/purchase/rate"
      ),
  }, //供应链采购频次详情

  {
    name: "WholesaleSupplierDetail",
    path: "/operation/purchase/supplier",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Operation/purchase/supplier"
      ),
  }, //供应链供货商订单详情

  /**用户 */
  {
    name: "WholesaleNewUser",
    path: "/users/newuser",
    component: () =>
      import(/* webpackChunkName: "wholesale" */ "../Wholesale/Users/newuser"),
  }, //用户新增
  {
    name: "WholesaleRepurchase",
    path: "/users/repurchase",
    component: () =>
      import(
        /* webpackChunkName: "wholesale" */ "../Wholesale/Users/repurchase"
      ),
  }, //用户转化复购
  {
    name: "WholesaleRetained",
    path: "/users/retained",
    component: () =>
      import(/* webpackChunkName: "wholesale" */ "../Wholesale/Users/retained"),
  }, //用户留存
  {
    name: "WholesaleWastage",
    path: "/users/wastage",
    component: () =>
      import(/* webpackChunkName: "wholesale" */ "../Wholesale/Users/wastage"),
  }, //用户流失
];
