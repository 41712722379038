/** 海那边业态 */
export const sea = [
  {
    path: "/sea/sales",
    name: "SeaSales",
    component: () => import(/*webpackChunkName:"sea" */ "../Sea/Sales/index"),
  }, //销售
  {
    path: "/sea/sales/cityDetail",
    name: "SeaSalesCityDetail",
    component: () =>
      import(/*webpackChunkName:"sea" */ "../Sea/Sales/cityDetail"),
  }, //销售--客户详情
  {
    path: "/sea/sales/storeDetail",
    name: "SeaSalesStoreDetail",
    component: () =>
      import(/*webpackChunkName:"sea" */ "../Sea/Sales/storeDetail"),
  }, //销售--客户退货详情
  {
    path: "/sea/sales/sellerDetail",
    name: "SeaSalesSellerDetail",
    component: () =>
      import(/*webpackChunkName:"sea" */ "../Sea/Sales/sellerDetail"),
  }, //销售--销售员详情
  {
    path: "/sea/sales/sellerRefundDetail",
    name: "SeaSalesSellerRefundDetail",
    component: () =>
      import(/*webpackChunkName:"sea" */ "../Sea/Sales/sellerRefundDetail"),
  }, //销售--销售员退货详情
  {
    path: "/sea/product",
    name: "SeaProduct",
    component: () => import(/*webpackChunkName:"sea" */ "../Sea/Product/index"),
  }, //商品
  {
    path: "/sea/product/classificationDetail",
    name: "SeaProductClassificationDetail",
    component: () =>
      import(/*webpackChunkName:"sea" */ "../Sea/Product/classificationDetail"),
  }, //商品--商品分类详情
  {
    path: "/sea/product/salesDetail",
    name: "SeaProductSalesDetail",
    component: () =>
      import(/*webpackChunkName:"sea" */ "../Sea/Product/salesDetail"),
  }, //商品--销售详情
  {
    path: "/sea/product/refundDetail",
    name: "SeaProductRefundDetail",
    component: () =>
      import(/*webpackChunkName:"sea" */ "../Sea/Product/refundDetail"),
  }, //商品--退货详情
  {
    path: "/sea/saling",
    name: "SeaSaling",
    component: () => import(/*webpackChunkName:"sea" */ "../Sea/Saling/index"),
  }, //实时销售
  {
    path: "/sea/saling/saleDetail",
    name: "SeaSalingSaleDetail",
    component: () =>
      import(/*webpackChunkName:"sea" */ "../Sea/Saling/saleDetail"),
  }, //实时销售--商品分类详情
  {
    path: "/sea/saling/storeDetail",
    name: "SeaSalingStoreDetail",
    component: () =>
      import(/*webpackChunkName:"sea" */ "../Sea/Saling/storeDetail"),
  }, //实时销售--门店详情
  {
    path: "/sea/saling/salesDetail",
    name: "SeaSalingSalesDetail",
    component: () =>
      import(/*webpackChunkName:"sea" */ "../Sea/Saling/salesDetail"),
  }, //实时销售--商品详情
  {
    path: "/sea/saling/sellerDetail",
    name: "SeaSalingSellerDetail",
    component: () =>
      import(/*webpackChunkName:"sea" */ "../Sea/Saling/sellerDetail"),
  }, //实时销售--销售员详情
  {
    name: "SeaInventory",
    path: "/sea/inventory",
    component: () =>
      import(/*webpackChunkName:"sea" */ "../Sea/Inventory/index"),
  }, //存货模块
  {
    name: "SeaInventoryClassificationDetail",
    path: "/sea/inventory/classificationDetail",
    component: () =>
      import(
        /*webpackChunkName:"sea" */ "../Sea/Inventory/classificationDetail"
      ),
  }, //存货模块--商品分类详情
  {
    name: "SeaAbcDetail",
    path: "/sea/inventory/abcDetail",
    component: () =>
      import(/*webpackChunkName:"sea" */ "../Sea/Inventory/abcDetail"),
  }, //存货--ABC详情
  {
    name: "SeaCountDetail",
    path: "/sea/inventory/countDetail",
    component: () =>
      import(/*webpackChunkName:"sea" */ "../Sea/Inventory/countDetail"),
  }, //存货--存货量详情
  {
    name: "SeaStockTurnover",
    path: "/sea/stockTurnover",
    component: () =>
      import(/*webpackChunkName:"sea" */ "../Sea/StockTurnover/index"),
  }, //存货周转--首页
  {
    name: "SeaTurnoverDetail",
    path: "/sea/stockTurnover/turnoverDetail",
    component: () =>
      import(
        /* webpackChunkName: "sea" */ "../Sea/StockTurnover/turnoverDetail"
      ),
  }, //存货周转详情
  {
    path: "/sea/shopping",
    name: "SeaShopping",
    component: () =>
      import(/* webpackChunkName:"sea" */ "../Sea/Shopping/index"),
  }, //实时采购
  {
    path: "/sea/shopping/detail",
    name: "SeaShoppingDetail",
    component: () =>
      import(/* webpackChunkName:"sea" */ "../Sea/Shopping/detail"),
  }, //实时采购--商品分类详情
  {
    path: "/sea/shopping/storeDetail",
    name: "SeaShoppingStoreDetail",
    component: () =>
      import(/* webpackChunkName:"sea" */ "../Sea/Shopping/storeDetail"),
  }, //供货商订货详情
  {
    path: "/sea/shopping/goodsDetail",
    name: "SeaShoppingGoodsDetail",
    component: () =>
      import(/* webpackChunkName:"sea" */ "../Sea/Shopping/goodsDetail"),
  }, //商品采购详情

  {
    path: "/sea/purchase",
    name: "SeaPurchase",
    component: () =>
      import(/* webpackChunkName:"sea" */ "../Sea/Purchase/index"),
  }, //采购
  {
    path: "/sea/purchase/classificationDetail",
    name: "SeaPurchaseClassificationDetail",
    component: () =>
      import(
        /* webpackChunkName:"sea" */ "../Sea/Purchase/classificationDetail"
      ),
  }, //采购--采购分类详情
  {
    path: "/sea/purchase/goodsDetail",
    name: "SeaPurchaseGoodsDetail",
    component: () =>
      import(/* webpackChunkName:"sea" */ "../Sea/Purchase/goodsDetail"),
  }, //采购商品详情
  {
    path: "/sea/purchase/detail",
    name: "SeaPurchaseDetail",
    component: () =>
      import(/* webpackChunkName:"sea" */ "../Sea/Purchase/detail"),
  }, //采购商品详情--商品详情
  {
    path: "/sea/purchase/storeDetail",
    name: "SeaPurchaseStoreDetail",
    component: () =>
      import(/* webpackChunkName:"sea" */ "../Sea/Purchase/storeDetail"),
  }, //供货商订货详情
];
