/** 菊花开工厂业态 */
export const jhkFactory = [
  {
    path: "/jhkFactory/sales",
    name: "JhkFactorySales",
    component: () =>
      import(/*webpackChunkName:"jhkFactory" */ "../JhkFactory/Sales/index"),
  }, //销售
  {
    path: "/jhkFactory/sales/cityDetail",
    name: "JhkFactorySalesCityDetail",
    component: () =>
      import(
        /*webpackChunkName:"jhkFactory" */ "../JhkFactory/Sales/cityDetail"
      ),
  }, //销售--客户详情
  {
    path: "/jhkFactory/sales/storeDetail",
    name: "JhkFactorySalesStoreDetail",
    component: () =>
      import(
        /*webpackChunkName:"jhkFactory" */ "../JhkFactory/Sales/storeDetail"
      ),
  }, //销售--客户退货详情
  {
    path: "/jhkFactory/sales/sellerDetail",
    name: "JhkFactorySalesSellerDetail",
    component: () =>
      import(
        /*webpackChunkName:"jhkFactory" */ "../JhkFactory/Sales/sellerDetail"
      ),
  }, //销售--销售员详情
  {
    path: "/jhkFactory/product",
    name: "JhkFactoryProduct",
    component: () =>
      import(/*webpackChunkName:"jhkFactory" */ "../JhkFactory/Product/index"),
  }, //商品
  {
    path: "/jhkFactory/product/classificationDetail",
    name: "JhkFactoryProductClassificationDetail",
    component: () =>
      import(
        /*webpackChunkName:"jhkFactory" */ "../JhkFactory/Product/classificationDetail"
      ),
  }, //商品--商品分类详情
  {
    path: "/jhkFactory/product/salesDetail",
    name: "JhkFactoryProductSalesDetail",
    component: () =>
      import(
        /*webpackChunkName:"jhkFactory" */ "../JhkFactory/Product/salesDetail"
      ),
  }, //商品--销售详情
  {
    path: "/jhkFactory/product/refundDetail",
    name: "JhkFactoryProductRefundDetail",
    component: () =>
      import(
        /*webpackChunkName:"jhkFactory" */ "../JhkFactory/Product/refundDetail"
      ),
  }, //商品--退货详情
  {
    path: "/jhkFactory/saling",
    name: "JhkFactorySaling",
    component: () =>
      import(/*webpackChunkName:"jhkFactory" */ "../JhkFactory/Saling/index"),
  }, //实时销售
  {
    path: "/jhkFactory/saling/saleDetail",
    name: "JhkFactorySalingSaleDetail",
    component: () =>
      import(
        /*webpackChunkName:"jhkFactory" */ "../JhkFactory/Saling/saleDetail"
      ),
  }, //实时销售
  {
    path: "/jhkFactory/saling/storeDetail",
    name: "JhkFactorySalingStoreDetail",
    component: () =>
      import(
        /*webpackChunkName:"jhkFactory" */ "../JhkFactory/Saling/storeDetail"
      ),
  }, //实时销售--门店详情
  {
    path: "/jhkFactory/saling/salesDetail",
    name: "JhkFactorySalingSalesDetail",
    component: () =>
      import(
        /*webpackChunkName:"jhkFactory" */ "../JhkFactory/Saling/salesDetail"
      ),
  }, //实时销售--商品详情
  {
    path: "/jhkFactory/saling/sellerDetail",
    name: "JhkFactorySalingSellerDetail",
    component: () =>
      import(
        /*webpackChunkName:"jhkFactory" */ "../JhkFactory/Saling/sellerDetail"
      ),
  }, //实时销售--销售员详情
  {
    name: "JhkFactoryInventory",
    path: "/jhkFactory/inventory",
    component: () =>
      import(
        /*webpackChunkName:"jhkFactory" */ "../JhkFactory/Inventory/index"
      ),
  }, //存货模块
  {
    name: "JhkFactoryInventoryClassificationDetail",
    path: "/jhkFactory/inventory/classificationDetail",
    component: () =>
      import(
        /*webpackChunkName:"jhkFactory" */ "../JhkFactory/Inventory/classificationDetail"
      ),
  }, //存货模块
  {
    name: "JhkFactoryInventoryDetail",
    path: "/jhkFactory/inventory/detail",
    component: () =>
      import(
        /*webpackChunkName:"jhkFactory" */ "../JhkFactory/Inventory/detail"
      ),
  }, //存货--分类详情
  {
    name: "JhkFactoryAbcDetail",
    path: "/jhkFactory/inventory/abcDetail",
    component: () =>
      import(
        /*webpackChunkName:"jhkFactory" */ "../JhkFactory/Inventory/abcDetail"
      ),
  }, //存货--ABC详情
  {
    name: "JhkFactoryRefundDetail",
    path: "/jhkFactory/inventory/refundDetail",
    component: () =>
      import(
        /*webpackChunkName:"jhkFactory" */ "../JhkFactory/Inventory/refundDetail"
      ),
  }, //存货--加工品存货数详情
  {
    name: "JhkFactoryTurnoverDetail",
    path: "/jhkFactory/inventory/turnoverDetail",
    component: () =>
      import(
        /* webpackChunkName: "jhkFactory" */ "../JhkFactory/Inventory/turnoverDetail"
      ),
  }, //存货周转详情
  {
    path: "/jhkFactory/shopping",
    name: "JhkFactoryShopping",
    component: () =>
      import(
        /* webpackChunkName:"jhkFactory" */ "../JhkFactory/Shopping/index"
      ),
  }, //实时采购
  {
    path: "/jhkFactory/shopping/detail",
    name: "JhkFactoryShoppingDetail",
    component: () =>
      import(
        /* webpackChunkName:"jhkFactory" */ "../JhkFactory/Shopping/detail"
      ),
  }, //实时采购--商品采购分类详情
  {
    path: "/jhkFactory/shopping/storeDetail",
    name: "JhkFactoryShoppingStoreDetail",
    component: () =>
      import(
        /* webpackChunkName:"jhkFactory" */ "../JhkFactory/Shopping/storeDetail"
      ),
  }, //供货商订货详情
  {
    path: "/jhkFactory/shopping/goodsDetail",
    name: "JhkFactoryShoppingGoodsDetail",
    component: () =>
      import(
        /* webpackChunkName:"jhkFactory" */ "../JhkFactory/Shopping/goodsDetail"
      ),
  }, //商品采购详情

  {
    path: "/jhkFactory/purchase",
    name: "JhkFactoryPurchase",
    component: () =>
      import(
        /* webpackChunkName:"jhkFactory" */ "../JhkFactory/Purchase/index"
      ),
  }, //采购
  {
    path: "/jhkFactory/purchase/classificationDetail",
    name: "JhkFactoryPurchaseClassificationDetail",
    component: () =>
      import(
        /* webpackChunkName:"jhkFactory" */ "../JhkFactory/Purchase/classificationDetail"
      ),
  }, //采购
  {
    path: "/jhkFactory/purchase/goodsDetail",
    name: "JhkFactoryPurchaseGoodsDetail",
    component: () =>
      import(
        /* webpackChunkName:"jhkFactory" */ "../JhkFactory/Purchase/goodsDetail"
      ),
  }, //采购商品详情
  {
    path: "/jhkFactory/purchase/detail",
    name: "JhkFactoryPurchaseDetail",
    component: () =>
      import(
        /* webpackChunkName:"jhkFactory" */ "../JhkFactory/Purchase/detail"
      ),
  }, //采购商品详情--商品详情
  {
    path: "/jhkFactory/purchase/storeDetail",
    name: "JhkFactoryPurchaseStoreDetail",
    component: () =>
      import(
        /* webpackChunkName:"jhkFactory" */ "../JhkFactory/Purchase/storeDetail"
      ),
  }, //供货商订货详情
];
