/* 大酱出川业态 */
export const bigsauce = [
  {
    path: "/bigsauce/home",
    name: "BigSauceHome",
    component: () =>
      import(/* webpackChunkName:"bigsauce" */ "../BigSauce/Home/index"),
  }, //实时销售
  {
    path: "/bigsauce/home/classificationDetail",
    name: "BigSauceHomeClassificationDetail",
    component: () =>
      import(
        /* webpackChunkName:"bigsauce" */ "../BigSauce/Home/classificationDetail"
      ),
  }, //实时销售--商品销售分类详情
  {
    path: "/bigsauce/home/storeDetail",
    name: "BigSauceStoreDetail",
    component: () =>
      import(/* webpackChunkName:"bigsauce" */ "../BigSauce/Home/storeDetail"),
  }, //客户销售详情
  {
    path: "/bigsauce/home/classDetail",
    name: "BigSauceClassDetail",
    component: () =>
      import(/* webpackChunkName:"bigsauce" */ "../BigSauce/Home/classDetail"),
  }, //商品销售分类详情
  {
    path: "/bigsauce/home/goodsDetail",
    name: "BigSauceGoodsDetail",
    component: () =>
      import(/* webpackChunkName:"bigsauce" */ "../BigSauce/Home/goodsDetail"),
  }, //热销商品详情

  {
    path: "/bigsauce/purchase",
    name: "BigSaucePurchase",
    component: () =>
      import(/* webpackChunkName:"bigsauce" */ "../BigSauce/Purchase/index"),
  }, //实时采购
  {
    path: "/bigsauce/purchase/saleDetail",
    name: "BigSaucePurchaseSaleDetail",
    component: () =>
      import(
        /* webpackChunkName:"bigsauce" */ "../BigSauce/Purchase/saleDetail"
      ),
  }, //实时采购--商品采购分类详情
  {
    path: "/bigsauce/purchase/storeDetail",
    name: "BigSaucePurchaseStoreDetail",
    component: () =>
      import(
        /* webpackChunkName:"bigsauce" */ "../BigSauce/Purchase/storeDetail"
      ),
  }, //供货商订货详情
  {
    path: "/bigsauce/purchase/classDetail",
    name: "BigSaucePurchaseClassDetail",
    component: () =>
      import(
        /* webpackChunkName:"bigsauce" */ "../BigSauce/Purchase/classDetail"
      ),
  }, //商品采购分类详情
  {
    path: "/bigsauce/purchase/goodsDetail",
    name: "BigSaucePurchaseGoodsDetail",
    component: () =>
      import(
        /* webpackChunkName:"bigsauce" */ "../BigSauce/Purchase/goodsDetail"
      ),
  }, //商品采购详情

  {
    path: "/bigsauce/shopping",
    name: "BigSauceShopping",
    component: () =>
      import(/* webpackChunkName:"bigsauce" */ "../BigSauce/Shopping/index"),
  }, //采购
  {
    path: "/bigsauce/shopping/classDetail",
    name: "BigSauceShoppingClassDetail",
    component: () =>
      import(
        /* webpackChunkName:"bigsauce" */ "../BigSauce/Shopping/classDetail"
      ),
  }, //采购分类详情
  {
    path: "/bigsauce/shopping/goodsDetail",
    name: "BigSauceShoppingGoodsDetail",
    component: () =>
      import(
        /* webpackChunkName:"bigsauce" */ "../BigSauce/Shopping/goodsDetail"
      ),
  }, //采购商品详情
  {
    path: "/bigsauce/shopping/detail",
    name: "BigSauceShoppingDetail",
    component: () =>
      import(/* webpackChunkName:"bigsauce" */ "../BigSauce/Shopping/detail"),
  }, //采购商品详情--商品详情
  {
    path: "/bigsauce/shopping/storeDetail",
    name: "BigSauceShoppingStoreDetail",
    component: () =>
      import(
        /* webpackChunkName:"bigsauce" */ "../BigSauce/Shopping/storeDetail"
      ),
  }, //供货商订货详情

  {
    path: "/bigsauce/sales",
    name: "BigSauceSales",
    component: () =>
      import(/* webpackChunkName:"bigsauce" */ "../BigSauce/Sales/index"),
  }, //销售
  {
    path: "/bigsauce/sales/customerDetail",
    name: "BigSauceSalescustomerDetail",
    component: () =>
      import(
        /* webpackChunkName:"bigsauce" */ "../BigSauce/Sales/customerDetail"
      ),
  }, //销售--客户详情

  {
    path: "/bigsauce/goods",
    name: "BigSauceGoods",
    component: () =>
      import(/* webpackChunkName:"bigsauce" */ "../BigSauce/Goods/index"),
  }, //商品
  {
    path: "/bigsauce/goods/classificationDetail",
    name: "BigSauceGoodsClassificationDetail",
    component: () =>
      import(
        /* webpackChunkName:"bigsauce" */ "../BigSauce/Goods/classificationDetail"
      ),
  }, //商品--商品销售分类详情
  {
    path: "/bigsauce/goods/classDetail",
    name: "BigSauceGoodsclassDetail",
    component: () =>
      import(/* webpackChunkName:"bigsauce" */ "../BigSauce/Goods/classDetail"),
  }, //商品--销售分类详情
  {
    path: "/bigsauce/goods/salesDetail",
    name: "BigSauceGoodssalesDetail",
    component: () =>
      import(/* webpackChunkName:"bigsauce" */ "../BigSauce/Goods/salesDetail"),
  }, //商品--热销详情
  {
    path: "/bigsauce/goods/customerDetail",
    name: "BigSauceGoodscustomerDetail",
    component: () =>
      import(
        /* webpackChunkName:"bigsauce" */ "../BigSauce/Goods/customerDetail"
      ),
  }, //商品--退货详情

  {
    name: "BigSauceInventory",
    path: "/bigsauce/inventory",
    component: () =>
      import(/*webpackChunkName:"bigsauce" */ "../BigSauce/Inventory/index"),
  }, //存货模块
  {
    name: "BigSauceInventoryClassificationDetail",
    path: "/bigsauce/inventory/classificationDetail",
    component: () =>
      import(
        /*webpackChunkName:"bigsauce" */ "../BigSauce/Inventory/classificationDetail"
      ),
  }, //存货模块
  {
    name: "BigSauceInventoryDetail",
    path: "/bigsauce/inventory/detail",
    component: () =>
      import(/*webpackChunkName:"bigsauce" */ "../BigSauce/Inventory/detail"),
  }, //存货--分类详情
  {
    name: "BigSauceAbcDetail",
    path: "/bigsauce/inventory/abcDetail",
    component: () =>
      import(
        /*webpackChunkName:"bigsauce" */ "../BigSauce/Inventory/abcDetail"
      ),
  }, //存货--ABC详情
  {
    name: "BigSauceRefundDetail",
    path: "/bigsauce/inventory/refundDetail",
    component: () =>
      import(
        /*webpackChunkName:"bigsauce" */ "../BigSauce/Inventory/refundDetail"
      ),
  }, //存货--加工品存货数详情
];
