/** 丸来丸去业态 */
export const wanzi = [
  {
    path: "/wanzi/sales",
    name: "WanZiSales",
    component: () =>
      import(/*webpackChunkName:"wanzi" */ "../WanZi/Sales/index"),
  }, //销售
  {
    path: "/wanzi/sales/cityDetail",
    name: "WanZiSalesCityDetail",
    component: () =>
      import(/*webpackChunkName:"wanzi" */ "../WanZi/Sales/cityDetail"),
  }, //销售--客户详情
  {
    path: "/wanzi/sales/storeDetail",
    name: "WanZiSalesStoreDetail",
    component: () =>
      import(/*webpackChunkName:"wanzi" */ "../WanZi/Sales/storeDetail"),
  }, //销售--客户退货详情
  {
    path: "/wanzi/sales/sellerDetail",
    name: "WanZiSalesSellerDetail",
    component: () =>
      import(/*webpackChunkName:"wanzi" */ "../WanZi/Sales/sellerDetail"),
  }, //销售--销售员详情
  {
    path: "/wanzi/sales/sellerRefundDetail",
    name: "WanZiSalesSellerRefundDetail",
    component: () =>
      import(/*webpackChunkName:"wanzi" */ "../WanZi/Sales/sellerRefundDetail"),
  }, //销售--销售员退货详情
  {
    path: "/wanzi/product",
    name: "WanZiProduct",
    component: () =>
      import(/*webpackChunkName:"wanzi" */ "../WanZi/Product/index"),
  }, //商品
  {
    path: "/wanzi/product/classificationDetail",
    name: "WanZiProductClassificationDetail",
    component: () =>
      import(
        /*webpackChunkName:"wanzi" */ "../WanZi/Product/classificationDetail"
      ),
  }, //商品--分类详情
  {
    path: "/wanzi/product/salesDetail",
    name: "WanZiProductSalesDetail",
    component: () =>
      import(/*webpackChunkName:"wanzi" */ "../WanZi/Product/salesDetail"),
  }, //商品--销售详情
  {
    path: "/wanzi/product/refundDetail",
    name: "WanZiProductRefundDetail",
    component: () =>
      import(/*webpackChunkName:"wanzi" */ "../WanZi/Product/refundDetail"),
  }, //商品--退货详情
  {
    path: "/wanzi/saling",
    name: "WanZiSaling",
    component: () =>
      import(/*webpackChunkName:"wanzi" */ "../WanZi/Saling/index"),
  }, //实时销售
  {
    path: "/wanzi/saling/saleDetail",
    name: "WanZiSalingSaleDetail",
    component: () =>
      import(/*webpackChunkName:"wanzi" */ "../WanZi/Saling/saleDetail"),
  }, //实时销售--商品分类详情
  {
    path: "/wanzi/saling/storeDetail",
    name: "WanZiSalingStoreDetail",
    component: () =>
      import(/*webpackChunkName:"wanzi" */ "../WanZi/Saling/storeDetail"),
  }, //实时销售--门店详情
  {
    path: "/wanzi/saling/salesDetail",
    name: "WanZiSalingSalesDetail",
    component: () =>
      import(/*webpackChunkName:"wanzi" */ "../WanZi/Saling/salesDetail"),
  }, //实时销售--商品详情
  {
    path: "/wanzi/saling/sellerDetail",
    name: "WanZiSalingSellerDetail",
    component: () =>
      import(/*webpackChunkName:"wanzi" */ "../WanZi/Saling/sellerDetail"),
  }, //实时销售--销售员详情
  {
    name: "WanZiInventory",
    path: "/wanzi/inventory",
    component: () =>
      import(/*webpackChunkName:"wanzi" */ "../WanZi/Inventory/index"),
  }, //存货模块
  {
    name: "WanZiInventoryClassificationDetail",
    path: "/wanzi/inventory/classificationDetail",
    component: () =>
      import(
        /*webpackChunkName:"wanzi" */ "../WanZi/Inventory/classificationDetail"
      ),
  }, //存货模块--商品分类
  {
    name: "WanZiAbcDetail",
    path: "/wanzi/inventory/abcDetail",
    component: () =>
      import(/*webpackChunkName:"wanzi" */ "../WanZi/Inventory/abcDetail"),
  }, //存货--ABC详情
  {
    name: "WanZiCountDetail",
    path: "/wanzi/inventory/countDetail",
    component: () =>
      import(/*webpackChunkName:"wanzi" */ "../WanZi/Inventory/countDetail"),
  }, //存货--存货量详情
  {
    name: "WanZiStockTurnover",
    path: "/wanzi/stockTurnover",
    component: () =>
      import(/*webpackChunkName:"wanzi" */ "../WanZi/StockTurnover/index"),
  }, //存货周转--首页
  {
    name: "WanZiTurnoverDetail",
    path: "/wanzi/stockTurnover/turnoverDetail",
    component: () =>
      import(
        /* webpackChunkName: "wanzi" */ "../WanZi/StockTurnover/turnoverDetail"
      ),
  }, //存货周转详情
  {
    path: "/wanzi/shopping",
    name: "WanZiShopping",
    component: () =>
      import(/* webpackChunkName:"wanzi" */ "../WanZi/Shopping/index"),
  }, //实时采购
  {
    path: "/wanzi/shopping/detail",
    name: "WanZiShoppingDetail",
    component: () =>
      import(/* webpackChunkName:"wanzi" */ "../WanZi/Shopping/detail"),
  }, //实时采购--商品分类详情
  {
    path: "/wanzi/shopping/storeDetail",
    name: "WanZiShoppingStoreDetail",
    component: () =>
      import(/* webpackChunkName:"wanzi" */ "../WanZi/Shopping/storeDetail"),
  }, //供货商订货详情
  {
    path: "/wanzi/shopping/goodsDetail",
    name: "WanZiShoppingGoodsDetail",
    component: () =>
      import(/* webpackChunkName:"wanzi" */ "../WanZi/Shopping/goodsDetail"),
  }, //商品采购详情

  {
    path: "/wanzi/purchase",
    name: "WanZiPurchase",
    component: () =>
      import(/* webpackChunkName:"wanzi" */ "../WanZi/Purchase/index"),
  }, //采购
  {
    path: "/wanzi/purchase/classificationDetail",
    name: "WanZiPurchaseClassificationDetail",
    component: () =>
      import(
        /* webpackChunkName:"wanzi" */ "../WanZi/Purchase/classificationDetail"
      ),
  }, //采购--分类构成详情
  {
    path: "/wanzi/purchase/goodsDetail",
    name: "WanZiPurchaseGoodsDetail",
    component: () =>
      import(/* webpackChunkName:"wanzi" */ "../WanZi/Purchase/goodsDetail"),
  }, //采购商品详情
  {
    path: "/wanzi/purchase/detail",
    name: "WanZiPurchaseDetail",
    component: () =>
      import(/* webpackChunkName:"wanzi" */ "../WanZi/Purchase/detail"),
  }, //采购商品详情--商品详情
  {
    path: "/wanzi/purchase/storeDetail",
    name: "WanZiPurchaseStoreDetail",
    component: () =>
      import(/* webpackChunkName:"wanzi" */ "../WanZi/Purchase/storeDetail"),
  }, //供货商订货详情
];
