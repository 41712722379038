/* 澄明业态 */
export const clarity = [
  {
    name: "ClaritySurvey",
    path: "/clarity/survey",
    component: () =>
      import(/* webpackChunkName: "clarity" */ "../Clarity/survey"),
  }, //本日概况

  {
    name: "ClarityChannel",
    path: "/clarity/channel",
    component: () =>
      import(/* webpackChunkName: "clarity" */ "../Clarity/channel"),
  }, // 渠道
  {
    name: "ClarityChannelReturnDetail",
    path: "/clarity/channel/returnDetail",
    component: () =>
      import(
        /* webpackChunkName: "clarity" */ "../Clarity/channel/returnDetail"
      ),
  }, // 渠道退货详情
  {
    name: "ClarityChannelSaleDetail",
    path: "/clarity/channel/saleDetail",
    component: () =>
      import(/* webpackChunkName: "clarity" */ "../Clarity/channel/saleDetail"),
  }, // 渠道热销详情

  {
    name: "ClarityTheater",
    path: "/clarity/theater",
    component: () =>
      import(/* webpackChunkName: "clarity" */ "../Clarity/theater"),
  }, // 战区
  {
    name: "ClarityTheaterReturnDetail",
    path: "/clarity/theater/returnDetail",
    component: () =>
      import(
        /* webpackChunkName: "clarity" */ "../Clarity/theater/returnDetail"
      ),
  }, // 战区退货详情
  {
    name: "ClarityTheaterSaleDetail",
    path: "/clarity/theater/saleDetail",
    component: () =>
      import(/* webpackChunkName: "clarity" */ "../Clarity/theater/saleDetail"),
  }, // 战区热销详情

  {
    name: "ClarityRegion",
    path: "/clarity/region",
    component: () =>
      import(/* webpackChunkName: "clarity" */ "../Clarity/region"),
  }, // 大区
  {
    name: "ClarityRegionSaleDetail",
    path: "/clarity/region/saleDetail",
    component: () =>
      import(/* webpackChunkName: "clarity" */ "../Clarity/region/saleDetail"),
  }, // 大区热销详情
  {
    name: "ClarityRegionReturnDetail",
    path: "/clarity/region/returnDetail",
    component: () =>
      import(
        /* webpackChunkName: "clarity" */ "../Clarity/region/returnDetail"
      ),
  }, // 大区退货详情

  {
    name: "ClarityHuman",
    path: "/clarity/human",
    component: () =>
      import(/* webpackChunkName: "clarity" */ "../Clarity/human"),
  }, // 人效

  {
    name: "ClarityCost",
    path: "/clarity/cost",
    component: () =>
      import(/* webpackChunkName: "clarity" */ "../Clarity/cost"),
  }, //费用
  {
    name: "CostDetail",
    path: "/clarity/cost/detail",
    component: () =>
      import(/* webpackChunkName: "clarity" */ "../Clarity/cost/detail"),
  }, //费用详情
  {
    name: "ClarityGoods",
    path: "/clarity/goods",
    component: () =>
      import(/* webpackChunkName: "clarity" */ "../Clarity/goods"),
  }, //商品
  {
    name: "ClaritySaleDetail",
    path: "/clarity/goods/saleDetail",
    component: () =>
      import(/* webpackChunkName: "clarity" */ "../Clarity/goods/saleDetail"),
  }, //商品销售详情
  {
    name: "ClarityReturnDetail",
    path: "/clarity/goods/returnDetail",
    component: () =>
      import(/* webpackChunkName: "clarity" */ "../Clarity/goods/returnDetail"),
  }, //商品退货详情

  {
    name: "ClarityCustomer",
    path: "/clarity/customer",
    component: () =>
      import(/* webpackChunkName: "clarity" */ "../Clarity/customer"),
  }, //客户
  {
    name: "ClarityCustomerOperate",
    path: "/clarity/customer/operateDetail",
    component: () =>
      import(
        /* webpackChunkName: "clarity" */ "../Clarity/customer/operateDetail"
      ),
  }, //客户经营详情
  {
    name: "ClarityCustomerOrder",
    path: "/clarity/customer/orderDetail",
    component: () =>
      import(
        /* webpackChunkName: "clarity" */ "../Clarity/customer/orderDetail"
      ),
  }, //客户订货详情
  {
    name: "ClarityCustomerRate",
    path: "/clarity/customer/rateDetail",
    component: () =>
      import(
        /* webpackChunkName: "clarity" */ "../Clarity/customer/rateDetail"
      ),
  }, //客户订货频次
  {
    name: "ClarityCustomerReturn",
    path: "/clarity/customer/returnDetail",
    component: () =>
      import(
        /* webpackChunkName: "clarity" */ "../Clarity/customer/returnDetail"
      ),
  }, //客户退货

  {
    name: "ClaritySeller",
    path: "/clarity/seller",
    component: () =>
      import(/* webpackChunkName: "clarity" */ "../Clarity/seller"),
  }, // 销售员
  {
    name: "ClarityOperateDetail",
    path: "/clarity/seller/operateDetail",
    component: () =>
      import(
        /* webpackChunkName: "clarity" */ "../Clarity/seller/operateDetail"
      ),
  }, // 销售员经营详情
  {
    name: "ClarityOperateHotDetail",
    path: "/clarity/seller/hotDetail",
    component: () =>
      import(/* webpackChunkName: "clarity" */ "../Clarity/seller/hotDetail"),
  }, // 销售员热销详情
  {
    name: "ClarityOperateReturnDetail",
    path: "/clarity/seller/returnDetail",
    component: () =>
      import(
        /* webpackChunkName: "clarity" */ "../Clarity/seller/returnDetail"
      ),
  }, // 销售员退货详情
  {
    name: "ClarityOperateSellerDetail",
    path: "/clarity/seller/seller",
    component: () =>
      import(/* webpackChunkName: "clarity" */ "../Clarity/seller/seller"),
  }, // 销售员退货详情
];
