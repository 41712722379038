/** 茂源肉业业态 */
export const maoyuan = [
  {
    path: "/maoyuan/sales",
    name: "MaoYuanSales",
    component: () =>
      import(/*webpackChunkName:"maoyuan" */ "../MaoYuan/Sales/index"),
  }, //销售
  {
    path: "/maoyuan/sales/cityDetail",
    name: "MaoYuanSalesCityDetail",
    component: () =>
      import(/*webpackChunkName:"maoyuan" */ "../MaoYuan/Sales/cityDetail"),
  }, //销售--客户详情
  {
    path: "/maoyuan/sales/storeDetail",
    name: "MaoYuanSalesStoreDetail",
    component: () =>
      import(/*webpackChunkName:"maoyuan" */ "../MaoYuan/Sales/storeDetail"),
  }, //销售--客户退货详情
  {
    path: "/maoyuan/sales/sellerDetail",
    name: "MaoYuanSalesSellerDetail",
    component: () =>
      import(/*webpackChunkName:"maoyuan" */ "../MaoYuan/Sales/sellerDetail"),
  }, //销售--销售员详情
  {
    path: "/maoyuan/sales/sellerRefundDetail",
    name: "MaoYuanSalesSellerRefundDetail",
    component: () =>
      import(
        /*webpackChunkName:"maoyuan" */ "../MaoYuan/Sales/sellerRefundDetail"
      ),
  }, //销售--销售员退货详情
  {
    path: "/maoyuan/product",
    name: "MaoYuanProduct",
    component: () =>
      import(/*webpackChunkName:"maoyuan" */ "../MaoYuan/Product/index"),
  }, //商品
  {
    path: "/maoyuan/product/classificationDetail",
    name: "MaoYuanProductClassificationDetail",
    component: () =>
      import(
        /*webpackChunkName:"maoyuan" */ "../MaoYuan/Product/classificationDetail"
      ),
  }, //商品--商品分类详情
  {
    path: "/maoyuan/product/salesDetail",
    name: "MaoYuanProductSalesDetail",
    component: () =>
      import(/*webpackChunkName:"maoyuan" */ "../MaoYuan/Product/salesDetail"),
  }, //商品--销售详情
  {
    path: "/maoyuan/product/refundDetail",
    name: "MaoYuanProductRefundDetail",
    component: () =>
      import(/*webpackChunkName:"maoyuan" */ "../MaoYuan/Product/refundDetail"),
  }, //商品--退货详情
  {
    path: "/maoyuan/saling",
    name: "MaoYuanSaling",
    component: () =>
      import(/*webpackChunkName:"maoyuan" */ "../MaoYuan/Saling/index"),
  }, //实时销售
  {
    path: "/maoyuan/saling/saleDetail",
    name: "MaoYuanSalingSaleDetail",
    component: () =>
      import(/*webpackChunkName:"maoyuan" */ "../MaoYuan/Saling/saleDetail"),
  }, //实时销售--商品分类详情
  {
    path: "/maoyuan/saling/storeDetail",
    name: "MaoYuanSalingStoreDetail",
    component: () =>
      import(/*webpackChunkName:"maoyuan" */ "../MaoYuan/Saling/storeDetail"),
  }, //实时销售--门店详情
  {
    path: "/maoyuan/saling/salesDetail",
    name: "MaoYuanSalingSalesDetail",
    component: () =>
      import(/*webpackChunkName:"maoyuan" */ "../MaoYuan/Saling/salesDetail"),
  }, //实时销售--商品详情
  {
    path: "/maoyuan/saling/sellerDetail",
    name: "MaoYuanSalingSellerDetail",
    component: () =>
      import(/*webpackChunkName:"maoyuan" */ "../MaoYuan/Saling/sellerDetail"),
  }, //实时销售--销售员详情
  {
    name: "MaoYuanInventory",
    path: "/maoyuan/inventory",
    component: () =>
      import(/*webpackChunkName:"maoyuan" */ "../MaoYuan/Inventory/index"),
  }, //存货模块
  {
    name: "MaoYuanAbcDetail",
    path: "/maoyuan/inventory/abcDetail",
    component: () =>
      import(/*webpackChunkName:"maoyuan" */ "../MaoYuan/Inventory/abcDetail"),
  }, //存货--ABC详情
  {
    name: "MaoYuanCountDetail",
    path: "/maoyuan/inventory/countDetail",
    component: () =>
      import(
        /*webpackChunkName:"maoyuan" */ "../MaoYuan/Inventory/countDetail"
      ),
  }, //存货--存货量详情
  {
    name: "MaoYuanStockTurnover",
    path: "/maoyuan/stockTurnover",
    component: () =>
      import(/*webpackChunkName:"maoyuan" */ "../MaoYuan/StockTurnover/index"),
  }, //存货周转--首页
  {
    name: "MaoYuanTurnoverDetail",
    path: "/maoyuan/stockTurnover/turnoverDetail",
    component: () =>
      import(
        /* webpackChunkName: "maoyuan" */ "../MaoYuan/StockTurnover/turnoverDetail"
      ),
  }, //存货周转详情
  {
    path: "/maoyuan/shopping",
    name: "MaoYuanShopping",
    component: () =>
      import(/* webpackChunkName:"maoyuan" */ "../MaoYuan/Shopping/index"),
  }, //实时采购
  {
    path: "/maoyuan/shopping/detail",
    name: "MaoYuanShoppingDetail",
    component: () =>
      import(/* webpackChunkName:"maoyuan" */ "../MaoYuan/Shopping/detail"),
  }, //实时采购--商品采购分类
  {
    path: "/maoyuan/shopping/storeDetail",
    name: "MaoYuanShoppingStoreDetail",
    component: () =>
      import(
        /* webpackChunkName:"maoyuan" */ "../MaoYuan/Shopping/storeDetail"
      ),
  }, //供货商订货详情
  {
    path: "/maoyuan/shopping/goodsDetail",
    name: "MaoYuanShoppingGoodsDetail",
    component: () =>
      import(
        /* webpackChunkName:"maoyuan" */ "../MaoYuan/Shopping/goodsDetail"
      ),
  }, //商品采购详情

  {
    path: "/maoyuan/purchase",
    name: "MaoYuanPurchase",
    component: () =>
      import(/* webpackChunkName:"maoyuan" */ "../MaoYuan/Purchase/index"),
  }, //采购
  {
    path: "/maoyuan/purchase/classificationDetail",
    name: "MaoYuanPurchaseClassificationDetail",
    component: () =>
      import(
        /* webpackChunkName:"maoyuan" */ "../MaoYuan/Purchase/classificationDetail"
      ),
  }, //采购--采购分类构成
  {
    path: "/maoyuan/purchase/goodsDetail",
    name: "MaoYuanPurchaseGoodsDetail",
    component: () =>
      import(
        /* webpackChunkName:"maoyuan" */ "../MaoYuan/Purchase/goodsDetail"
      ),
  }, //采购商品详情
  {
    path: "/maoyuan/purchase/detail",
    name: "MaoYuanPurchaseDetail",
    component: () =>
      import(/* webpackChunkName:"maoyuan" */ "../MaoYuan/Purchase/detail"),
  }, //采购商品详情--商品详情
  {
    path: "/maoyuan/purchase/storeDetail",
    name: "MaoYuanPurchaseStoreDetail",
    component: () =>
      import(
        /* webpackChunkName:"maoyuan" */ "../MaoYuan/Purchase/storeDetail"
      ),
  }, //供货商订货详情
];
