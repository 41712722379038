/**合一业态 */
export const combine = [
  {
    name: "CombineHome",
    path: "/combine/home",
    component: () =>
      import(/*webpackChunkName:"combine" */ "../Combine/Home/index"),
  }, //实时销售
  {
    name: "CombineHomeClassificationDetail",
    path: "/combine/home/classificationDetail",
    component: () =>
      import(
        /*webpackChunkName:"combine" */ "../Combine/Home/classificationDetail"
      ),
  }, //实时销售--商品销售分类详情
  {
    name: "CombineCustomerDetail",
    path: "/combine/home/customerDetail",
    component: () =>
      import(/*webpackChunkName:"combine" */ "../Combine/Home/customerDetail"),
  }, //客户销售详情
  {
    name: "CombineSellerDetail",
    path: "/combine/home/sellerDetail",
    component: () =>
      import(/*webpackChunkName:"combine" */ "../Combine/Home/sellerDetail"),
  }, //销售员销售详情
  {
    name: "CombineSaleDetail",
    path: "/combine/home/saleDetail",
    component: () =>
      import(/*webpackChunkName:"combine" */ "../Combine/Home/saleDetail"),
  }, //商品销售分类详情
  {
    name: "CombineHotDetail",
    path: "/combine/home/hotDetail",
    component: () =>
      import(/*webpackChunkName:"combine" */ "../Combine/Home/hotDetail"),
  }, //热销商品--商品详情

  /**实时采购 */
  {
    name: "CombineShopping",
    path: "/combine/shopping",
    component: () =>
      import(/*webpackChunkName:"combine" */ "../Combine/Shopping/index"),
  }, //实时采购
  {
    name: "CombineShoppingClassificationDetail",
    path: "/combine/shopping/classificationDetail",
    component: () =>
      import(
        /*webpackChunkName:"combine" */ "../Combine/Shopping/classificationDetail"
      ),
  }, //实时采购--商品采购分类详情
  {
    name: "CombineShoppingSupplierDetail",
    path: "/combine/shopping/supplierDetail",
    component: () =>
      import(
        /*webpackChunkName:"combine" */ "../Combine/Shopping/supplierDetail"
      ),
  }, //供货商订货详情
  {
    name: "CombineShoppingPurchaseDetail",
    path: "/combine/shopping/purchaseDetail",
    component: () =>
      import(
        /*webpackChunkName:"combine" */ "../Combine/Shopping/purchaseDetail"
      ),
  }, //商品采购分类详情
  {
    name: "CombineShoppingProductDetail",
    path: "/combine/shopping/productDetail",
    component: () =>
      import(
        /*webpackChunkName:"combine" */ "../Combine/Shopping/productDetail"
      ),
  }, //商品采购详情

  {
    name: "CombineGoods",
    path: "/combine/goods",
    component: () =>
      import(/*webpackChunkName:"combine" */ "../Combine/Goods/index"),
  }, //商品模块
  {
    name: "CombineGoodsClassificationDetail",
    path: "/combine/goods/classificationDetail",
    component: () =>
      import(
        /*webpackChunkName:"combine" */ "../Combine/Goods/classificationDetail"
      ),
  }, //商品模块--商品分类详情
  {
    name: "CombineGoodsSalesDetail",
    path: "/combine/goods/salesDetail",
    component: () =>
      import(/*webpackChunkName:"combine" */ "../Combine/Goods/salesDetail"),
  }, //商品销售分类详情
  {
    name: "CombineHotsDetail",
    path: "/combine/goods/hotsDetail",
    component: () =>
      import(/*webpackChunkName:"combine" */ "../Combine/Goods/hotsDetail"),
  }, //商品--热销商品详情
  {
    name: "CombineReturnDetail",
    path: "/combine/goods/returnDetail",
    component: () =>
      import(/*webpackChunkName:"combine" */ "../Combine/Goods/returnDetail"),
  }, //商品--退货详情

  {
    name: "CombinePurchaseClassificationDetail",
    path: "/combine/purchase/classificationDetail",
    component: () =>
      import(
        /*webpackChunkName:"combine" */ "../Combine/Purchase/classificationDetail"
      ),
  }, //采购模块
  {
    name: "CombinePurchase",
    path: "/combine/purchase",
    component: () =>
      import(/*webpackChunkName:"combine" */ "../Combine/Purchase/index"),
  }, //采购模块
  {
    name: "CombinePurchasePurchaseDetail",
    path: "/combine/purchase/purchaseDetail",
    component: () =>
      import(
        /*webpackChunkName:"combine" */ "../Combine/Purchase/purchaseDetail"
      ),
  }, //采购--采购分类详情
  {
    name: "CombinePurchaseThermalDetail",
    path: "/combine/purchase/thermalDetail",
    component: () =>
      import(
        /*webpackChunkName:"combine" */ "../Combine/Purchase/thermalDetail"
      ),
  }, //采购--热采商品详情
  {
    name: "CombinePurchaseSupplierDetail",
    path: "/combine/purchase/supplierDetail",
    component: () =>
      import(
        /*webpackChunkName:"combine" */ "../Combine/Purchase/supplierDetail"
      ),
  }, //采购--供货商采购详情
  {
    name: "CombineCombineDetail",
    path: "/combine/purchase/detail",
    component: () =>
      import(/*webpackChunkName:"combine" */ "../Combine/Purchase/detail"),
  }, //采购--供货商详情--详情

  {
    name: "CombineInventory",
    path: "/combine/inventory",
    component: () =>
      import(/*webpackChunkName:"combine" */ "../Combine/Inventory/index"),
  }, //存货模块
  {
    name: "CombineInventoryClassificationDetail",
    path: "/combine/inventory/classificationDetail",
    component: () =>
      import(
        /*webpackChunkName:"combine" */ "../Combine/Inventory/classificationDetail"
      ),
  }, //存货模块--商品分类详情
  {
    name: "CombineInventoryDetail",
    path: "/combine/inventory/detail",
    component: () =>
      import(/*webpackChunkName:"combine" */ "../Combine/Inventory/detail"),
  }, //存货--分类详情
  {
    name: "CombineAbcDetail",
    path: "/combine/inventory/abcDetail",
    component: () =>
      import(/*webpackChunkName:"combine" */ "../Combine/Inventory/abcDetail"),
  }, //存货--分类详情

  {
    name: "CombineSales",
    path: "/combine/sales",
    component: () =>
      import(/*webpackChunkName:"combine" */ "../Combine/Sales/index"),
  }, //销售模块
  {
    name: "CombineSalesDetail",
    path: "/combine/sales/salesDetail",
    component: () =>
      import(/*webpackChunkName:"combine" */ "../Combine/Sales/salesDetail"),
  }, //销售--销售员
  {
    name: "CombineSalesCustomerDetail",
    path: "/combine/sales/customerDetail",
    component: () =>
      import(/*webpackChunkName:"combine" */ "../Combine/Sales/customerDetail"),
  }, //销售--客户详情
];
