/* 野火烧工厂端业态 */
export const wildfireFactory = [
  {
    path: "/wildfireFactory/home",
    name: "WildfireFactoryHome",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Home/index"
      ),
  }, //实时销售
  {
    path: "/wildfireFactory/home/customeDetail",
    name: "WildfireFactoryCustomeDetail",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Home/customeDetail"
      ),
  }, //客户销售详情
  {
    path: "/wildfireFactory/home/storeDetail",
    name: "WildfireFactoryStoreDetail",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Home/storeDetail"
      ),
  }, //销售员销售详情
  {
    path: "/wildfireFactory/home/classDetail",
    name: "WildfireFactoryClassDetail",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Home/classDetail"
      ),
  }, //商品销售分类详情
  {
    path: "/wildfireFactory/home/goodsDetail",
    name: "WildfireFactoryGoodsDetail",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Home/goodsDetail"
      ),
  }, //热销商品详情

  {
    path: "/wildfireFactory/purchase",
    name: "WildfireFactoryPurchase",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Purchase/index"
      ),
  }, //实时采购
  {
    path: "/wildfireFactory/purchase/storeDetail",
    name: "WildfireFactoryPurchaseStoreDetail",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Purchase/storeDetail"
      ),
  }, //供货商订货详情
  {
    path: "/wildfireFactory/purchase/classDetail",
    name: "WildfireFactoryPurchaseClassDetail",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Purchase/classDetail"
      ),
  }, //商品采购分类详情
  {
    path: "/wildfireFactory/purchase/goodsDetail",
    name: "WildfireFactoryPurchaseGoodsDetail",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Purchase/goodsDetail"
      ),
  }, //商品采购详情

  {
    path: "/wildfireFactory/shopping",
    name: "WildfireFactoryShopping",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Shopping/index"
      ),
  }, //采购
  {
    path: "/wildfireFactory/shopping/classDetail",
    name: "WildfireFactoryShoppingClassDetail",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Shopping/classDetail"
      ),
  }, //采购分类详情
  {
    path: "/wildfireFactory/shopping/goodsDetail",
    name: "WildfireFactoryShoppingGoodsDetail",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Shopping/goodsDetail"
      ),
  }, //采购商品详情
  {
    path: "/wildfireFactory/shopping/detail",
    name: "WildfireFactoryShoppingDetail",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Shopping/detail"
      ),
  }, //采购商品详情--商品详情
  {
    path: "/wildfireFactory/shopping/storeDetail",
    name: "WildfireFactoryShoppingStoreDetail",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Shopping/storeDetail"
      ),
  }, //供货商订货详情

  {
    path: "/wildfireFactory/sales",
    name: "WildfireFactorySales",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Sales/index"
      ),
  }, //销售
  {
    path: "/wildfireFactory/sales/customerDetail",
    name: "WildfireFactorySalescustomerDetail",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Sales/customerDetail"
      ),
  }, //销售--客户详情
  {
    path: "/wildfireFactory/sales/sellerDetail",
    name: "WildfireFactorySalesSellerDetail",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Sales/sellerDetail"
      ),
  }, //销售--销售员详情

  {
    path: "/wildfireFactory/goods",
    name: "WildfireFactoryGoods",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Goods/index"
      ),
  }, //商品
  {
    path: "/wildfireFactory/goods/classDetail",
    name: "WildfireFactoryGoodsclassDetail",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Goods/classDetail"
      ),
  }, //商品--销售分类详情
  {
    path: "/wildfireFactory/goods/salesDetail",
    name: "WildfireFactoryGoodssalesDetail",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Goods/salesDetail"
      ),
  }, //商品--热销详情
  {
    path: "/wildfireFactory/goods/customerDetail",
    name: "WildfireFactoryGoodscustomerDetail",
    component: () =>
      import(
        /* webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Goods/customerDetail"
      ),
  }, //商品--退货详情

  {
    name: "WildfireFactoryInventory",
    path: "/wildfireFactory/inventory",
    component: () =>
      import(
        /*webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Inventory/index"
      ),
  }, //存货模块
  {
    name: "WildfireFactoryInventoryDetail",
    path: "/wildfireFactory/inventory/detail",
    component: () =>
      import(
        /*webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Inventory/detail"
      ),
  }, //存货--分类详情
  {
    name: "WildfireFactoryAbcDetail",
    path: "/wildfireFactory/inventory/abcDetail",
    component: () =>
      import(
        /*webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Inventory/abcDetail"
      ),
  }, //存货--ABC详情
  {
    name: "WildfireFactoryRefundDetail",
    path: "/wildfireFactory/inventory/refundDetail",
    component: () =>
      import(
        /*webpackChunkName:"wildfireFactory" */ "../WildfireFactory/Inventory/refundDetail"
      ),
  }, //存货--加工品存货数详情
];
