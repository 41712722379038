import { createRouter, createWebHashHistory } from "vue-router";
import { wholesale } from "./wholesale";
import { clarity } from "./clarity";
import { wildfire } from "./wildfire";
import { combine } from "./combine";
import { luyi } from "./luyi";
import { bigsauce } from "./bigsauce";
import { supply } from "./supply";
import { hutong } from "./hutong";
import { smallfish } from "./smallfish";
import { jhk } from "./jhk";
import { jhkFactory } from "./jhkFactory";
import { dxjFactory } from "./dxjFactory";
import { longxia } from "./longxia";
import { wanzi } from "./wanzi";
import { maoyuan } from "./maoyuan";
import { sea } from "./sea";
import { wildfireFactory } from "./wildfireFactory";
import { huading } from "./huading";

const routes = [
  {
    path: "/",
    redirect: "/wholesale",
  },
  {
    path: "/wholesale",
    name: "Wholesale",
    component: () => import("@/layout/wholesale"),
    children: wholesale,
  },
  {
    path: "/clarity",
    name: "Clarity",
    component: () => import("@/layout/clarity"),
    children: clarity,
  },
  {
    path: "/wildfire",
    name: "Wildfire",
    component: () => import("@/layout/wildfire"),
    children: wildfire,
  },
  {
    path: "/combine",
    name: "Combine",
    component: () => import("@/layout/luyi"),
    children: combine,
  },
  {
    path: "/luyi",
    name: "Luyi",
    component: () => import("@/layout/bigsauce"),
    children: luyi,
  },
  {
    path: "/bigsauce",
    name: "BigSauce",
    component: () => import("@/layout/bigsauce"),
    children: bigsauce,
  },
  {
    path: "/supply",
    name: "Supply",
    component: () => import("@/layout/supply"),
    children: supply,
  },
  {
    path: "/smallfish",
    name: "SmallFish",
    component: () => import("@/layout/smallfish"),
    children: smallfish,
  },
  {
    path: "/hutong",
    name: "Hutong",
    component: () => import("@/layout/hutong"),
    children: hutong,
  },
  {
    path: "/jhk",
    name: "Jhk",
    component: () => import("@/layout/jhk"),
    children: jhk,
  },
  {
    path: "/jhkFactory",
    name: "JhkFactory",
    component: () => import("@/layout/jhkFactory"),
    children: jhkFactory,
  },
  {
    path: "/dxjFactory",
    name: "DxjFactory",
    component: () => import("@/layout/dxjFactory"),
    children: dxjFactory,
  },
  {
    path: "/longxia",
    name: "LongXia",
    component: () => import("@/layout/longxia"),
    children: longxia,
  },
  {
    path: "/wanzi",
    name: "WanZi",
    component: () => import("@/layout/wanzi"),
    children: wanzi,
  },
  {
    path: "/maoyuan",
    name: "MaoYuan",
    component: () => import("@/layout/maoyuan"),
    children: maoyuan,
  },
  {
    path: "/sea",
    name: "Sea",
    component: () => import("@/layout/sea"),
    children: sea,
  },
  {
    path: "/wildfireFactory",
    name: "WildfireFactory",
    component: () => import("@/layout/wildfireFactory"),
    children: wildfireFactory,
  },
  {
    path: "/huading",
    name: "HuaDing",
    component: () => import("@/layout/huading"),
    children: huading,
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
  // scrollBehavior: () => ({ top: 0 }), //路由跳转后页面回到顶部
});

//解决VUE3.0 No match found for location with path
router.beforeEach((to, from, next) => {
  next();
});

export default router;
