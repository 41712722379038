/* 野火烧门店端业态 */
export const wildfire = [
  {
    path: "/wildfire/home",
    name: "WildfireIndex",
    component: () =>
      import(/* webpackChunkName: "wildfire" */ "../Wildfire/Home/index"),
  }, //本日概况
  {
    path: "/wildfire/home/storeDetail",
    name: "WildfireStoreDetail",
    component: () =>
      import(/* webpackChunkName: "wildfire" */ "../Wildfire/Home/storeDetail"),
  }, //门店详情
  {
    path: "/wildfire/home/goodsDetail",
    name: "WildfireStoreGoodsDetail",
    component: () =>
      import(/* webpackChunkName: "wildfire" */ "../Wildfire/Home/goodsDetail"),
  }, //热销商品
  {
    path: "/wildfire/home/classDetail",
    name: "WildfireClassDetail",
    component: () =>
      import(/* webpackChunkName: "wildfire" */ "../Wildfire/Home/classDetail"),
  }, //商品分类

  /**@params 商品维度 */
  {
    path: "/wildfire/goods",
    name: "WildfireGoods",
    component: () =>
      import(/* webpackChunkName: "wildfire" */ "../Wildfire/Goods/index"),
  }, //商品维度
  {
    path: "/wildfire/goods/detail",
    name: "WildfireGoodsDetail",
    component: () =>
      import(/* webpackChunkName: "wildfire" */ "../Wildfire/Goods/detail"),
  }, //商品维度--分类详情
  {
    path: "/wildfire/goods/hotDetail",
    name: "WildfireHotDetail",
    component: () =>
      import(/* webpackChunkName: "wildfire" */ "../Wildfire/Goods/hotDetail"),
  }, //商品维度--商品详情
  {
    path: "/wildfire/goods/returnDetail",
    name: "WildfireReturnDetail",
    component: () =>
      import(
        /* webpackChunkName: "wildfire" */ "../Wildfire/Goods/returnDetail"
      ),
  }, //商品维度--商品详情

  /**@params 门店维度 */
  {
    path: "/wildfire/store",
    name: "WildfireStore",
    component: () =>
      import(/* webpackChunkName: "wildfire" */ "../Wildfire/Store/index"),
  }, //门店维度
  {
    path: "/wildfire/store/detail",
    name: "WildfireDetail",
    component: () =>
      import(/* webpackChunkName: "wildfire" */ "../Wildfire/Store/detail"),
  }, //门店维度--详情

  /**@params 市场维度 */
  {
    path: "/wildfire/market",
    name: "WildfireMarket",
    component: () =>
      import(/* webpackChunkName: "wildfire" */ "../Wildfire/Market/index"),
  }, //市场维度
  {
    path: "/wildfire/market/newDetail",
    name: "WildfireNewDetail",
    component: () =>
      import(/* webpackChunkName: "wildfire" */ "../Wildfire/Market/newDetail"),
  }, //市场维度--新签门店详情
  {
    path: "/wildfire/market/payDetail",
    name: "WildfirePayDetail",
    component: () =>
      import(/* webpackChunkName: "wildfire" */ "../Wildfire/Market/payDetail"),
  }, //市场维度--缴纳保证金详情
  {
    path: "/wildfire/market/paymentDetail",
    name: "WildfirePaymentDetail",
    component: () =>
      import(
        /* webpackChunkName: "wildfire" */ "../Wildfire/Market/paymentDetail"
      ),
  }, //市场维度--缴纳--详情
  {
    path: "/wildfire/market/closeDetail",
    name: "WildfireCloseDetail",
    component: () =>
      import(
        /* webpackChunkName: "wildfire" */ "../Wildfire/Market/closeDetail"
      ),
  }, //市场维度--闭店详情
  {
    path: "/wildfire/market/backDetail",
    name: "WildfireBackDetail",
    component: () =>
      import(
        /* webpackChunkName: "wildfire" */ "../Wildfire/Market/backDetail"
      ),
  }, //市场维度--退保证金详情
  {
    path: "/wildfire/market/drawbackDetail",
    name: "WildfireDrawbackDetail",
    component: () =>
      import(
        /* webpackChunkName: "wildfire" */ "../Wildfire/Market/drawbackDetail"
      ),
  }, //市场维度--退款--详情

  /** @params 外卖 */

  {
    path: "/wildfire/tackaway",
    name: "WildfireTackaway",
    component: () =>
      import(/* webpackChunkName: "wildfire" */ "../Wildfire/Tackaway/index"),
  }, //外卖维度
  {
    path: "/wildfire/tackaway/storeDetail",
    name: "WildfireTackawayStoreDetail",
    component: () =>
      import(
        /* webpackChunkName: "wildfire" */ "../Wildfire/Tackaway/storeDetail"
      ),
  }, //外卖维度--门店详情
  {
    path: "/wildfire/tackaway/productDetail",
    name: "WildfireTackawayProductDetail",
    component: () =>
      import(
        /* webpackChunkName: "wildfire" */ "../Wildfire/Tackaway/productDetail"
      ),
  }, //外卖维度--商品详情

  {
    path: "/wildfire/members",
    name: "WildfireMembers",
    component: () =>
      import(/* webpackChunkName: "wildfire" */ "../Wildfire/Members/index"),
  }, //会员维度
  {
    path: "/wildfire/members/storeDetail",
    name: "WildfireMembersStoreDetail",
    component: () =>
      import(
        /* webpackChunkName: "wildfire" */ "../Wildfire/Members/storeDetail"
      ),
  }, //会员维度--门店详情
  {
    path: "/wildfire/members/saleDetail",
    name: "WildfireMembersSaleDetail",
    component: () =>
      import(
        /* webpackChunkName: "wildfire" */ "../Wildfire/Members/saleDetail"
      ),
  }, //会员维度--商品详情
];
