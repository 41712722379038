/* 野火烧供应链（贸易端）业态 */
export const supply = [
  {
    path: "/supply/home",
    name: "SupplyHome",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Home/index"),
  }, //实时销售
  {
    path: "/supply/home/customeDetail",
    name: "SupplyCustomeDetail",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Home/customeDetail"),
  }, //客户销售详情
  {
    path: "/supply/home/storeDetail",
    name: "SupplyStoreDetail",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Home/storeDetail"),
  }, //销售员销售详情
  {
    path: "/supply/home/classDetail",
    name: "SupplyClassDetail",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Home/classDetail"),
  }, //商品销售分类详情
  {
    path: "/supply/home/goodsDetail",
    name: "SupplyGoodsDetail",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Home/goodsDetail"),
  }, //热销商品详情

  {
    path: "/supply/purchase",
    name: "SupplyPurchase",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Purchase/index"),
  }, //实时采购
  {
    path: "/supply/purchase/storeDetail",
    name: "SupplyPurchaseStoreDetail",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Purchase/storeDetail"),
  }, //供货商订货详情
  {
    path: "/supply/purchase/classDetail",
    name: "SupplyPurchaseClassDetail",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Purchase/classDetail"),
  }, //商品采购分类详情
  {
    path: "/supply/purchase/goodsDetail",
    name: "SupplyPurchaseGoodsDetail",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Purchase/goodsDetail"),
  }, //商品采购详情

  {
    path: "/supply/shopping",
    name: "SupplyShopping",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Shopping/index"),
  }, //采购
  {
    path: "/supply/shopping/classDetail",
    name: "SupplyShoppingClassDetail",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Shopping/classDetail"),
  }, //采购分类详情
  {
    path: "/supply/shopping/goodsDetail",
    name: "SupplyShoppingGoodsDetail",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Shopping/goodsDetail"),
  }, //采购商品详情
  {
    path: "/supply/shopping/detail",
    name: "SupplyShoppingDetail",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Shopping/detail"),
  }, //采购商品详情--商品详情
  {
    path: "/supply/shopping/storeDetail",
    name: "SupplyShoppingStoreDetail",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Shopping/storeDetail"),
  }, //供货商订货详情

  {
    path: "/supply/sales",
    name: "SupplySales",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Sales/index"),
  }, //销售
  {
    path: "/supply/sales/customerDetail",
    name: "SupplySalescustomerDetail",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Sales/customerDetail"),
  }, //销售--客户详情
  {
    path: "/supply/sales/sellerDetail",
    name: "SupplySalesSellerDetail",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Sales/sellerDetail"),
  }, //销售--销售员详情

  {
    path: "/supply/goods",
    name: "SupplyGoods",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Goods/index"),
  }, //商品
  {
    path: "/supply/goods/classDetail",
    name: "SupplyGoodsclassDetail",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Goods/classDetail"),
  }, //商品--销售分类详情
  {
    path: "/supply/goods/salesDetail",
    name: "SupplyGoodssalesDetail",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Goods/salesDetail"),
  }, //商品--热销详情
  {
    path: "/supply/goods/customerDetail",
    name: "SupplyGoodscustomerDetail",
    component: () =>
      import(/* webpackChunkName:"supply" */ "../Supply/Goods/customerDetail"),
  }, //商品--退货详情

  {
    name: "SupplyInventory",
    path: "/supply/inventory",
    component: () =>
      import(/*webpackChunkName:"supply" */ "../Supply/Inventory/index"),
  }, //存货模块
  {
    name: "SupplyInventoryDetail",
    path: "/supply/inventory/detail",
    component: () =>
      import(/*webpackChunkName:"supply" */ "../Supply/Inventory/detail"),
  }, //存货--分类详情
  {
    name: "SupplyAbcDetail",
    path: "/supply/inventory/abcDetail",
    component: () =>
      import(/*webpackChunkName:"supply" */ "../Supply/Inventory/abcDetail"),
  }, //存货--ABC详情
  {
    name: "SupplyRefundDetail",
    path: "/supply/inventory/refundDetail",
    component: () =>
      import(/*webpackChunkName:"supply" */ "../Supply/Inventory/refundDetail"),
  }, //存货--加工品存货数详情
];
