/** 欢欢龙虾业态 */
export const longxia = [
  {
    path: "/longxia/sales",
    name: "LongXiaSales",
    component: () =>
      import(/*webpackChunkName:"longxia" */ "../LongXia/Sales/index"),
  }, //销售
  {
    path: "/longxia/sales/cityDetail",
    name: "LongXiaSalesCityDetail",
    component: () =>
      import(/*webpackChunkName:"longxia" */ "../LongXia/Sales/cityDetail"),
  }, //销售--客户详情
  {
    path: "/longxia/sales/storeDetail",
    name: "LongXiaSalesStoreDetail",
    component: () =>
      import(/*webpackChunkName:"longxia" */ "../LongXia/Sales/storeDetail"),
  }, //销售--客户退货详情
  {
    path: "/longxia/sales/sellerDetail",
    name: "LongXiaSalesSellerDetail",
    component: () =>
      import(/*webpackChunkName:"longxia" */ "../LongXia/Sales/sellerDetail"),
  }, //销售--销售员详情
  {
    path: "/longxia/sales/sellerAchievement",
    name: "LongXiaSalesSellerAchievement",
    component: () =>
      import(
        /*webpackChunkName:"longxia" */ "../LongXia/Sales/sellerAchievement"
      ),
  }, //销售--销售员业绩详情
  {
    path: "/longxia/sales/sellerRefundDetail",
    name: "LongXiaSalesSellerRefundDetail",
    component: () =>
      import(
        /*webpackChunkName:"longxia" */ "../LongXia/Sales/sellerRefundDetail"
      ),
  }, //销售--销售员退货详情
  {
    path: "/longxia/product",
    name: "LongXiaProduct",
    component: () =>
      import(/*webpackChunkName:"longxia" */ "../LongXia/Product/index"),
  }, //商品
  {
    path: "/longxia/product/salesDetail",
    name: "LongXiaProductSalesDetail",
    component: () =>
      import(/*webpackChunkName:"longxia" */ "../LongXia/Product/salesDetail"),
  }, //商品--销售详情
  {
    path: "/longxia/product/refundDetail",
    name: "LongXiaProductRefundDetail",
    component: () =>
      import(/*webpackChunkName:"longxia" */ "../LongXia/Product/refundDetail"),
  }, //商品--退货详情
  {
    path: "/longxia/saling",
    name: "LongXiaSaling",
    component: () =>
      import(/*webpackChunkName:"longxia" */ "../LongXia/Saling/index"),
  }, //实时销售
  {
    path: "/longxia/saling/storeDetail",
    name: "LongXiaSalingStoreDetail",
    component: () =>
      import(/*webpackChunkName:"longxia" */ "../LongXia/Saling/storeDetail"),
  }, //实时销售--门店详情
  {
    path: "/longxia/saling/salesDetail",
    name: "LongXiaSalingSalesDetail",
    component: () =>
      import(/*webpackChunkName:"longxia" */ "../LongXia/Saling/salesDetail"),
  }, //实时销售--商品详情
  {
    path: "/longxia/saling/sellerDetail",
    name: "LongXiaSalingSellerDetail",
    component: () =>
      import(/*webpackChunkName:"longxia" */ "../LongXia/Saling/sellerDetail"),
  }, //实时销售--销售员详情
  {
    name: "LongXiaInventory",
    path: "/longxia/inventory",
    component: () =>
      import(/*webpackChunkName:"longxia" */ "../LongXia/Inventory/index"),
  }, //存货模块
  {
    name: "LongXiaInventoryDetail",
    path: "/longxia/inventory/detail",
    component: () =>
      import(/*webpackChunkName:"longxia" */ "../LongXia/Inventory/detail"),
  }, //存货--分类详情
  {
    name: "LongXiaAbcDetail",
    path: "/longxia/inventory/abcDetail",
    component: () =>
      import(/*webpackChunkName:"longxia" */ "../LongXia/Inventory/abcDetail"),
  }, //存货--ABC详情
  {
    name: "LongXiaRefundDetail",
    path: "/longxia/inventory/refundDetail",
    component: () =>
      import(
        /*webpackChunkName:"longxia" */ "../LongXia/Inventory/refundDetail"
      ),
  }, //存货--加工品存货数详情
  {
    name: "LongXiaStockTurnover",
    path: "/longxia/stockTurnover",
    component: () =>
      import(/*webpackChunkName:"longxia" */ "../LongXia/StockTurnover/index"),
  }, //存货周转模块
  {
    name: "LongXiaTurnoverProductDetail",
    path: "/longxia/stockTurnover/productDetail",
    component: () =>
      import(
        /* webpackChunkName: "longxia" */ "../LongXia/StockTurnover/productDetail"
      ),
  }, //存货周转--商品详情
  {
    path: "/longxia/shopping",
    name: "LongXiaShopping",
    component: () =>
      import(/* webpackChunkName:"longxia" */ "../LongXia/Shopping/index"),
  }, //实时采购
  {
    path: "/longxia/shopping/detail",
    name: "LongXiaShoppingDetail",
    component: () =>
      import(/* webpackChunkName:"longxia" */ "../LongXia/Shopping/detail"),
  }, //实时采购--采购分类构成
  {
    path: "/longxia/shopping/storeDetail",
    name: "LongXiaShoppingStoreDetail",
    component: () =>
      import(
        /* webpackChunkName:"longxia" */ "../LongXia/Shopping/storeDetail"
      ),
  }, //供货商订货详情
  {
    path: "/longxia/shopping/goodsDetail",
    name: "LongXiaShoppingGoodsDetail",
    component: () =>
      import(
        /* webpackChunkName:"longxia" */ "../LongXia/Shopping/goodsDetail"
      ),
  }, //商品采购详情

  {
    path: "/longxia/purchase",
    name: "LongXiaPurchase",
    component: () =>
      import(/* webpackChunkName:"longxia" */ "../LongXia/Purchase/index"),
  }, //采购
  {
    path: "/longxia/purchase/classificationDetail",
    name: "LongXiaPurchaseClassificationDetail",
    component: () =>
      import(
        /* webpackChunkName:"longxia" */ "../LongXia/Purchase/classificationDetail"
      ),
  }, //采购--采购分类详情
  {
    path: "/longxia/purchase/goodsDetail",
    name: "LongXiaPurchaseGoodsDetail",
    component: () =>
      import(
        /* webpackChunkName:"longxia" */ "../LongXia/Purchase/goodsDetail"
      ),
  }, //采购商品详情
  {
    path: "/longxia/purchase/detail",
    name: "LongXiaPurchaseDetail",
    component: () =>
      import(/* webpackChunkName:"longxia" */ "../LongXia/Purchase/detail"),
  }, //采购商品详情--商品详情
  {
    path: "/longxia/purchase/storeDetail",
    name: "LongXiaPurchaseStoreDetail",
    component: () =>
      import(
        /* webpackChunkName:"longxia" */ "../LongXia/Purchase/storeDetail"
      ),
  }, //供货商订货详情
];
