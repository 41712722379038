export function getToken(key) {
  return sessionStorage.getItem(key) || "";
}

export function setToken(key, value) {
  return sessionStorage.setItem(key, value);
}

export function removeToken(key) {
  return sessionStorage.removeItem(key);
}
