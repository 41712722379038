<template>
  <router-view />
</template>

<script setup>
import * as echarts from "echarts";
import { provide, onMounted } from "vue";
// import { useRoute } from "vue-router";
import { setToken, getToken } from "@/utils/auth";
import { getQueryString } from "@/utils/tools";
// import { getCategory, getArea } from "@/api/common";
// 下拉框选项
/* const getOptions = async (type) => {
  if (type === "area") {
    return await getArea(); // 战区，店仓
  } else if (type === "category") {
    return await getCategory(); // 分类，单品
  }
}; */

provide("echarts", echarts);
// provide("area", getOptions("area"));
// provide("category", getOptions("category"));

onMounted(() => {
  const token = getQueryString("token");
  const project_id = getQueryString("projectId");
  document.getElementById("app").style.display = "block"; // 项目加载后去掉loading
  document.getElementById("loading").style.display = "none";
  if (token) {
    setToken("token", token);
    console.log("缓存token", getToken("token"));
  }
  if (project_id) {
    setToken("project_id", project_id);
    console.log("缓存project_id", getToken("project_id"));
  }
  // getOptions();
});
</script>

<style src="@/assets/style/row.css"></style>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
// 全局样式
#app {
  font-family: PingFang-SC-Medium, PingFang-SC, Avenir, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  min-height: 100vh;
  background-color: #070f21;
}
// 统计图样式
.tooltip {
  max-width: 700px;
  display: none;
  white-space: normal !important;
  background: linear-gradient(360deg, #3072ff 0%, #818aff 100%) !important;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.3) !important;
  padding: 15px !important;
  opacity: 0.8 !important;
  border: none !important;
  color: #fff !important;
  z-index: 30 !important;
  .amount,
  .order {
    .title {
      text-align: center;
      margin-bottom: 12px;
      font-size: 24px !important;
      line-height: 32px !important;
    }
    .item {
      margin-bottom: 10px;
      font-size: 22px !important;
      line-height: 30px !important;
      position: relative;
      padding-left: 22px;
    }
    .icon {
      position: absolute;
      left: 0;
      top: 10px;
      display: inline;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
// 省略号
.nowrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
